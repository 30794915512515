import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Divider } from '@mui/material'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'

import { FormComponent, ElementComponent } from 'components'

interface DiagnoseDataPageProps {
  isLoading?: boolean
  lastSubmitData: any
  submitDiagnoseResuslt: Function
}

const dataInitial = {
  result: '',
  diagnose: '',
  suggestion: '',
}

const validateSchema = yup.object({
  result: yup.string().required('กรุณากรอกผลลัพธ์การวินิจฉัยโรค'),
  diagnose: yup.string().required('กรุณากรอกรายละเอียดการวินิจฉัยเพิ่มเติม'),
  suggestion: yup.string().required('กรุณากรอกคำแนะนำสำหรับคนไข้'),
})

export default function DiagnoseDataPage({
  isLoading = false,
  submitDiagnoseResuslt,
  lastSubmitData,
}: DiagnoseDataPageProps) {
  const [searchParams] = useSearchParams()

  const handleDataInitial = (data: any): any => {
    if (!_.isEmpty(lastSubmitData)) {
      return {
        result: data.diagnosisResult,
        diagnose: data.description,
        suggestion: data.suggestion,
      }
    } else return dataInitial
  }

  return (
    <Formik
      initialValues={handleDataInitial(lastSubmitData)}
      enableReinitialize={true}
      validationSchema={validateSchema}
      onSubmit={(values) => {
        submitDiagnoseResuslt({
          diagnosisResult: values.result,
          description: values.diagnose,
          suggestion: values.suggestion,
        })
      }}
    >
      {(formik) => (
        <Form>
          <div
            className="px-[20px] py-[35px] mt-[23px] rounded-[10px] bg-white-main"
            style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
          >
            <p className="text-[20px] font-bold">วินิจฉัยโรค</p>
            <Divider sx={{ marginTop: '18px', marginBottom: '25px' }} />
            <FormComponent.DiagnoseForm loading={isLoading} form={formik} />
          </div>

          <div className="flex justify-end mt-[45px]">
            <ElementComponent.Button
              submit
              disable={Boolean(
                formik.errors.result ||
                  formik.errors.diagnose ||
                  formik.errors.suggestion ||
                  isLoading,
              )}
              id="submit-diagnose-button"
              width="w-main"
              height="52px"
              text={
                searchParams.get('editor') === 'true'
                  ? 'ยืนยันการเปลี่ยนเเปลงข้อมูล'
                  : 'ยืนยันส่งข้อมูล'
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}
