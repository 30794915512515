import React from 'react'
import { Grid, Skeleton } from '@mui/material'

export default function MedicalDataSkeleton() {
  return (
    <div className="p-[16px]">
      {Array(8)
        .fill(0)
        .map((_, index: number) => (
          <Grid key={`general-skeleton-${index}`} container columnSpacing="5px">
            <Grid item mobile={12} laptop={1}>
              <Skeleton width="100%" height="40px" />
            </Grid>
            <Grid item mobile={12} laptop={9}>
              <Skeleton width="100%" height="40px" />
            </Grid>
          </Grid>
        ))}

      <p className="font-bold mt-[20px]">แนบรูปภาพเพิ่มเติม</p>
      <hr className="mt-[10px] mb-[20px]" />
      <div className="w-full h-[200px] laptop:w-[170px] laptop:h-[170px]">
        <Skeleton variant="rounded" width="100%" height="100%" />
      </div>
    </div>
  )
}
