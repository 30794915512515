import React, { useState, useEffect } from 'react'
import { useQuery } from 'urql'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs'

import { ElementComponent } from 'components'
import { MedicalQuery } from 'services/graphql/medicalService'

interface SpecificDataModalProps {
  diseaseList: any[]
  open: boolean
  onClose: Function
  onSelect: Function
}

const validationSchema = yup.object({
  dId: yup.string().required('กรุณาเลือกค่าแลปของโรค'),
  date: yup.date().required('กรุณากรอกวัน/เดือน/ปี ข้อมูล'),
})

const initialValues = {
  dId: '',
  date: dayjs().toDate(),
}

function SpecificDataModal({
  diseaseList,
  open,
  onClose,
  onSelect,
}: Readonly<SpecificDataModalProps>) {
  const [diseaseOption, setDiseaseOption] = useState<
    ElementComponent.SelectBar.OptionSelectType[]
  >([])

  const [diseaseQuery] = useQuery(
    MedicalQuery.getDiseaseForDropDown({
      AND: [{ type: { equals: 'SPECIFIC' } }],
    }),
  )

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    ;(async () => {
      if (!diseaseQuery.fetching) {
        const { getDiseases } = await diseaseQuery.data
        setDiseaseOption(
          getDiseases
            .filter((item) => !diseaseList.includes(item.dId))
            .map((item: any) => ({ key: item.dId, value: item.thaiName })),
        )
      }
    })()
  }, [diseaseQuery, diseaseList])

  return (
    <ElementComponent.ModalForm open={open} onClose={() => handleClose()}>
      <div className="flex flex-col items-center w-screen max-w-[550px] max-h-[700px]">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            onSelect({
              disease: {
                dId: values.dId,
                thaiName: diseaseOption?.filter(
                  (item: any) => item.key === values.dId,
                )?.[0]?.value,
              },
              date: dayjs(values.date).toDate(),
            })
            handleClose()
          }}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit} className="w-full">
              <div className="p-[20px]">
                <p className="text-[20px] font-bold text-start w-full">
                  เพิ่มข้อมูลเฉพาะทาง
                </p>
              </div>

              <hr className="mb-[20px]" />

              <div className="px-[22px] pt-[20px] pb-[40px]">
                <ElementComponent.SelectBar.SelectBar
                  focusSpacing={false}
                  isLoading={diseaseQuery.fetching}
                  name="disease"
                  label="ค่าแลปของโรค"
                  placeholder="ค่าแลปของโรค"
                  width="w-full"
                  data={formik.values.dId}
                  option={diseaseOption}
                  onChange={(value: string) =>
                    formik.setFieldValue('dId', value)
                  }
                  errorText={formik.errors.dId}
                  error={formik.touched.dId && Boolean(formik.errors.dId)}
                />

                <div className="mt-[30px]">
                  <ElementComponent.DatePicker
                    dateValue={formik.values.date}
                    name="createDate"
                    isSubmitting={formik.isSubmitting}
                    errorText={formik.errors.date as string}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    onChange={(value: string) =>
                      formik.setFieldValue('date', value)
                    }
                    yearLength={2}
                  />
                </div>

                <p className="mt-[20px] mb-[40px] text-red-main">
                  <span className="font-bold">หมายเหตุ * </span>
                  <span>
                    เพื่อความแม่นยำของผลแลปกรุณาอัพโหลดค่าผลแลปย้อนหลังได้ไม่เกิน
                    3 วัน
                  </span>
                </p>

                <ElementComponent.Button
                  id="create-new-specific-diease-submit"
                  submit
                  text="ยืนยัน"
                  width="100%"
                  height="52px"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ElementComponent.ModalForm>
  )
}

export default SpecificDataModal
