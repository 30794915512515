import React from 'react'
import { Grid } from '@mui/material'
import { Icon } from '@iconify/react'

import { ElementComponent } from 'components'

interface ErrorModalProps {
  text: string
  icon?: string
  open: boolean
  onClose: Function
  handleConfirm: Function
  handleCancel: Function
}

function SuccessModal({
  text,
  icon,
  open,
  onClose,
  handleConfirm,
  handleCancel,
}: ErrorModalProps) {
  return (
    <ElementComponent.ModalForm open={open} onClose={() => onClose()}>
      <div className="flex flex-col items-center text-[20px] py-[52px] font-bold w-screen laptop:max-w-[532px] max-w-[450px]">
        <Icon
          icon={icon ?? 'material-symbols:warning-rounded'}
          className="w-[70px] h-[70px] mb-[30px] text-red-main"
        />
        <div className="px-[20px]">
          <p className="text-center px-[10px] mb-[50px]">{text}</p>
          <Grid
            container
            justifyContent="center"
            columns={{ mobile: 2.2 }}
            gap="5px"
          >
            <Grid item mobile={1}>
              <ElementComponent.Button
                id={'cancel-button'}
                shape="outlined"
                text="ยกเลิก"
                width="100%"
                height="52px"
                onAction={() => handleCancel()}
              />
            </Grid>
            <Grid item mobile={1}>
              <ElementComponent.Button
                id={'confirm-button'}
                text="ตกลง"
                width="100%"
                height="52px"
                onAction={() => handleConfirm()}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </ElementComponent.ModalForm>
  )
}

export default SuccessModal
