import React from 'react'

interface PaperProps {
  title: string
  children: React.ReactNode
}

function Paper({ title, children }: PaperProps) {
  return (
    <div
      className="bg-white-main rounded-[10px] pt-[15px] pb-[35px] px-[12px]"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1' }}
    >
      <p className="rounded-[5px] bg-white-darker p-[12px] mb-[20px] font-bold">
        {title}
      </p>

      {children}
    </div>
  )
}

export default Paper
