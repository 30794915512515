declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false
    sm: false
    md: false
    lg: false
    xl: false
    mobile: true
    tablet: true
    laptop: true
    desktop: true
  }
}

// Result in max-width to px
const screenLayout = {
  values: {
    mobile: 0,
    tablet: 767,
    laptop: 1024,
    desktop: 1280,
  },
}

export default screenLayout
