import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material'

import * as typography from './Typography'
import palette from './Palette'
import breakpoint from './Breakpoint'

interface Props {
  children: React.ReactNode
}

function Theme({ children }: Props) {
  const customTheme = createTheme({
    palette: palette,
    typography: typography.typography,
    breakpoints: breakpoint,
    components: {
      MuiTypography: {
        styleOverrides: {
          ...typography.MuiTypography,
        },
      },
    },
  })

  return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>
}

export default Theme
