import React, { useState, useEffect } from 'react'
import { useQuery } from 'urql'
import { useNavigate } from 'react-router-dom'
import { Tooltip, Pagination } from '@mui/material'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Icon } from '@iconify/react'
import { Formik, Form } from 'formik'
import { useRecoilState } from 'recoil'

import { UserQuery } from 'services/graphql/userService'
import { useUser } from 'stores/recoil'
import { ElementComponent, SkeletonComponent } from 'components'
import { route } from 'settings'
import { date } from 'utils'

export default function HealthDataManagementPage() {
  const navigate = useNavigate()
  const PAGE_SIZE = 10

  const topicTable = [
    'HN (DR.ASA)',
    'HN (สถานพยาบาล)',
    'สถานพยาบาล',
    'ชื่อ-นามสกุล',
    'อายุ',
    'วันที่บันทึกข้อมูล',
    'ตัวเลือก',
  ]

  const [searchTable, setSearchTable] = useState<string[]>([])
  const [pageAmount, setPageAmount] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const [user] = useRecoilState(useUser.seletor.seletorUser)

  const [userTableData, setUserTableData] = useState<any[]>([])
  const [userData] = useQuery({
    ...UserQuery.getUsers({
      where: {
        role: {
          is: {
            name: {
              equals: 'PATIENT',
            },
          },
        },
        ...(searchTable.length > 0
          ? {
              OR: searchTable,
            }
          : {}),
      },
      take: PAGE_SIZE,
      skip: (page - 1) * PAGE_SIZE,
    }),
  })

  const [userDataCount] = useQuery({
    ...UserQuery.getCountUsers({
      where: {
        role: {
          is: {
            name: {
              equals: 'PATIENT',
            },
          },
        },
        ...(searchTable.length > 0
          ? {
              OR: searchTable,
            }
          : {}),
      },
      take: PAGE_SIZE,
      skip: (page - 1) * PAGE_SIZE,
    }),
  })

  const handleOnClickAddUserButton = () => {
    window.location.href = route.CREATE_NEW_PATIENT
  }

  const option = (uId: string, disable = true) => {
    const StyleToolTip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#C9E2F4',
        color: '#1475BB',
        fontSize: 12,
      },
    })

    return (
      <div className="flex justify-center">
        <StyleToolTip title="แก้ไขข้อมูลคนไข้" color="info">
          <Icon
            icon="mdi:pencil"
            className={`w-[28px] h-[28px] ${
              disable
                ? 'cursor-not-allowed text-gray-main'
                : 'cursor-pointer hover:text-blue-main'
            }`}
            onClick={() =>
              !disable && navigate(`${route.CREATE_NEW_PATIENT}/${uId}`)
            }
          />
        </StyleToolTip>
        <StyleToolTip title="ดูค่าแลป" color="info">
          <Icon
            icon="material-symbols:article"
            className={`w-[28px] h-[28px] cursor-pointer hover:text-blue-main`}
            onClick={() => navigate(`${route.MEDICAL_DATA_PAGE_URL}/${uId}`)}
          />
        </StyleToolTip>
        <StyleToolTip title="เพิ่มค่าแลป" color="info">
          <Icon
            icon="material-symbols:add-circle"
            className={`w-[28px] h-[28px] cursor-pointer hover:text-blue-main`}
            onClick={() =>
              navigate(`${route.MEDICAL_DATA_PAGE_URL}/${uId}/create`)
            }
          />
        </StyleToolTip>
      </div>
    )
  }

  const formatData = (data) => {
    return data.map((item) => {
      const identity_id = `${Array(7 - item.hnId.toString().length)
        .fill(0)
        .join('')}${item.hnId}`

      const enterpriseHN =
        item.EnterpriseTreatmentPlaceTransaction[0]?.enterprise_hnId || '-'

      let ercId =
        item.EnterpriseTreatmentPlaceTransaction[0]?.enterpriseTreatmentPlace
          ?.ercId || '-'

      ercId = enterpriseHN === '-' ? '-' : ercId

      const enterpriseName =
        enterpriseHN === '-'
          ? '-'
          : item.EnterpriseTreatmentPlaceTransaction[0]
              ?.enterpriseTreatmentPlace?.thaiName || '-'

      return {
        [topicTable[0]]: {
          data: identity_id,
          color: 'black',
        },
        [topicTable[1]]: {
          data: enterpriseHN,
          color: 'black',
        },
        [topicTable[2]]: {
          data: enterpriseName,
          color: 'black',
        },
        [topicTable[3]]: {
          data: `${item.prefix} ${item.firstname} ${item.lastname}`,
          color: 'black',
        },
        [topicTable[4]]: {
          data: Math.floor(
            (new Date().getTime() -
              new Date(item.UserMedicalDemograhicData.birthdate).getTime()) /
              (1000 * 3600 * 24) /
              365.25,
          ),
          color: 'black',
        },
        [topicTable[5]]: {
          data: date.convertToShowDateTime(item.createdAt),
          color: 'black',
        },
        [topicTable[6]]: {
          data: option(item.uId, user['enterpriseId'] !== ercId),
          color: 'black',
        },
      }
    })
  }

  const handleSearchData = () => {
    setPage(1)
  }

  useEffect(() => {
    if (
      userData.fetching ||
      !userData.data ||
      userDataCount.fetching ||
      !userDataCount.data
    )
      return

    setPageAmount(
      Math.ceil((userDataCount.data.aggregateUser?._count.uId || 0) / 10),
    )
    const save_data = formatData(userData.data.getUsers || [])
    setUserTableData(save_data)
  }, [userData])

  useEffect(() => {
    setPage(1)
  }, [searchTable])

  return (
    <div className="p-[80px]">
      <div className="flex items-center gap-[10px]">
        <Icon
          icon="heroicons:document-chart-bar-solid"
          className="w-[28px] h-[28px]"
        />
        <p className="text-[24px]">จัดการข้อมูลผู้ใช้งาน</p>
      </div>

      <div
        className="mt-[20px] px-[20px] py-[30px] rounded-[8px] bg-white-main"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="flex flex-row justify-between">
          <div className="mb-[24px]">
            <Formik
              initialValues={{ text: '' }}
              onSubmit={() => handleSearchData()}
            >
              {(formik) => (
                <Form>
                  <div className="flex gap-[5px]">
                    <ElementComponent.InputBar
                      id="search-data-table"
                      name="text"
                      focusSpacing={false}
                      isLoading={false}
                      placeholder="ค้นหา ชื่อผู้ใช้งาน หรือ เบอร์โทรศัพท์"
                      height="40px"
                      frontIcon={
                        <Icon
                          icon="material-symbols:search-rounded"
                          className="w-[20px] h-[20px]"
                        />
                      }
                      value={formik.values.text}
                      onChange={(text: string) => {
                        formik.setFieldValue('text', text)
                        const search_keys = text.split(' ')
                        const search = []
                        search_keys.forEach((key) => {
                          search.push({
                            firstname: {
                              contains: key,
                            },
                          })
                          search.push({
                            lastname: {
                              contains: key,
                            },
                          })
                          search.push({
                            UserAuth: {
                              is: {
                                phoneNumber: {
                                  contains: key,
                                },
                              },
                            },
                          })
                        })

                        setSearchTable(search)
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="mb-[24px]">
            <ElementComponent.Button
              id="submit-button"
              onAction={handleOnClickAddUserButton}
              icon="material-symbols:add"
              text="เพิ่มข้อมูลคนไข้"
              width="200px"
              height="40px"
            />
          </div>
        </div>

        {!userData?.fetching || !userDataCount.fetching ? (
          <div>
            <ElementComponent.Table col={topicTable} row={userTableData} />
            {userTableData.length === 0 && (
              <div className="my-[80px]">
                <p className="text-center text-[18px] text-black-lighter">
                  ไม่มีข้อมูลคนผู้ใช้งาน
                </p>
              </div>
            )}
          </div>
        ) : (
          <SkeletonComponent.TableSkeleton columnsAmount={4} />
        )}

        {pageAmount !== 0 && (
          <div className="mt-[30px] flex justify-center">
            <Pagination
              page={page}
              count={pageAmount}
              variant="outlined"
              shape="rounded"
              onChange={(_, newPage: number) => setPage(newPage)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
