export const createPatient = `
mutation createPatient ($data: UserCreateInput!) {
  createPatient (data: $data) {
      uId
  }
}
`

export const updateUser = `
  mutation updateUserInfo ($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserInfo (data: $data, where: $where) {
      prefix
      firstname
      lastname
      hnId
      imagePath
      UserAuth {
        phoneNumber
      }
      UserMedicalDemograhicData {
        birthdate
        gender
        district
        province
      }
      EnterpriseTreatmentPlaceTransaction {
        enterpriseTreatmentPlace {
          thaiName
          ercId
        }
      }
    }
  }
`

export const updateUserPatient = `
  mutation updateUserInfo ($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserInfo (data: $data, where: $where) {
      uId
    }
  }
`

export const changePassword = `
  mutation changePassword ($data: UsersChangePasswordInput!) {
    changePassword (data: $data) {
        phoneNumber
        password
    }
  }
`
