import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Formik, Form } from 'formik'
import { useQuery } from 'urql'
import * as yup from 'yup'
import dayjs from 'dayjs'

import { ElementComponent } from 'components'
import { MedicalQuery } from 'services/graphql/medicalService'
import { date } from 'utils'

interface PatientDiagnoseFormProps {
  onChangeValue: Function
  loading: boolean
  disable: boolean
  gender: string
  age: string
  allergic?: string
  patientUnderlying: any[]
}

const dataInitial: any = {
  underlying: null,
  specialCase: '',
}

const validateSchema = yup.object({
  underlying: yup.array().of(
    yup.object().shape({
      key: yup.string(),
      value: yup.string(),
    }),
  ),
  specialCase: yup.string(),
})

export default function PatientDiagnoseForm({
  onChangeValue,
  loading,
  disable,
  gender,
  age,
  allergic = '',
  patientUnderlying,
}: PatientDiagnoseFormProps) {
  const [underlyingData] = useQuery(MedicalQuery.getUnderlyingDisease())

  const [optionsChoice, setOptionsChoice] = useState<any[]>([])

  const handleUserData = (patientDisease: any[]): any[] => {
    if (patientDisease) {
      return patientUnderlying.map((patient: any) => ({
        key: patient.underlyingDisease.udId,
        value: patient.underlyingDisease.medName,
      }))
    } else {
      return dataInitial.underlying
    }
  }

  const handleOptions = (underlying: any[]): any[] => {
    return underlying?.map((diseaseItem: any) => ({
      key: diseaseItem.udId,
      value: diseaseItem.medName,
    }))
  }

  useEffect(() => {
    if (underlyingData.fetching && !underlyingData.data) return

    const { getUnderlyingDiseases } = underlyingData.data
    setOptionsChoice(handleOptions(getUnderlyingDiseases))
    !disable && onChangeValue((prev) => ({ ...prev, specialCase: allergic }))
  }, [underlyingData])

  return (
    <div className="w-full">
      <Grid container>
        <Grid item mobile>
          <div className="flex gap-[10px]">
            <p className="font-bold">เพศ :</p>
            <p>{gender}</p>
          </div>
        </Grid>
        <Grid item mobile>
          <div className="flex gap-[10px]">
            <p className="font-bold">อายุ :</p>
            <p>{date.getAge(dayjs(age).toDate())}</p>
          </div>
        </Grid>
      </Grid>

      {disable ? (
        <Grid container sx={{ gap: '10px', marginTop: '15px' }}>
          <Grid item>
            <p className="mb-[10px] font-bold">โรคประจำตัว :</p>
            <p className="font-bold">แพ้ยา/แพ้อาหาร :</p>
          </Grid>
          <Grid item>
            <p className="mb-[10px]">
              {patientUnderlying?.length > 0
                ? patientUnderlying.map((item: any) => item.medName).join(' ')
                : 'ไม่มี'}
            </p>
            <p>{allergic}</p>
          </Grid>
        </Grid>
      ) : (
        <Formik
          initialValues={{
            underlying: handleUserData(patientUnderlying),
            specialCase: allergic,
          }}
          validationSchema={validateSchema}
          onSubmit={null}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form>
              <div className="flex flex-col gap-[20px] mt-[30px]">
                <ElementComponent.MultiSelectBar
                  name="underlying"
                  isLoading={!underlyingData.fetching || loading}
                  height="50px"
                  data={formik.values.underlying}
                  options={optionsChoice}
                  label="โรคประจำตัว"
                  placeholder="โรคประจำตัว"
                  onSelect={(options: string) => {
                    formik.setFieldValue('underlying', options)
                    onChangeValue((prev: any) => ({
                      ...prev,
                      underlying: options,
                    }))
                  }}
                />

                <ElementComponent.InputBar
                  id="patient-special-case"
                  name="specialCase"
                  label="แพ้ยา/แพ้อาหาร"
                  placeholder="แพ้ยา/แพ้อาหาร"
                  height="52px"
                  isLoading={loading}
                  value={formik.values.specialCase}
                  onChange={(value: string) => {
                    formik.setFieldValue('specialCase', value)
                    onChangeValue((prev: any) => ({
                      ...prev,
                      specialCase: value,
                    }))
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}
