import React, { useState } from 'react'
import { InputAdornment, Skeleton } from '@mui/material'
import { Field } from 'formik'
import { Icon } from '@iconify/react'

import { TextFieldStyle } from 'components/element'

interface PasswordBarProps {
  id: string
  name: string
  value: string
  label?: string
  error?: boolean
  errorText?: string
  height?: string
  width?: string
  icon?: React.ReactNode
  placeholder?: string
  onChange: Function
  isLoading?: boolean
  focusSpacing?: boolean
  style?: string
}

function PasswordBar({
  id,
  name,
  width = '100%',
  height,
  error = false,
  errorText,
  label,
  value,
  icon,
  placeholder,
  onChange,
  isLoading = false,
  focusSpacing = true,
  style,
}: PasswordBarProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [cliked, setCliked] = useState(false)

  const onHandleClick = (e, defaultOnClick) => {
    setCliked(true)

    if (defaultOnClick) defaultOnClick(e)
  }

  const onHandleBlur = (e, defaultOnblur) => {
    setCliked(false)

    if (defaultOnblur) defaultOnblur(e)
  }

  return !isLoading ? (
    <div
      className={`flex flex-col ${
        focusSpacing && (cliked || value)
          ? 'mt-[20px] transition-all ease-in-out'
          : ''
      }`}
    >
      <Field id={name} name={name}>
        {({ field }) => (
          <>
            <div className="relative w-fit">
              <p
                className={`text-black-main text-[16px] w-auto break-normal whitespace-nowrap text-left pl-1 font-bold ${
                  cliked || value
                    ? 'absolute left-0 bottom-[0.25px] duration-300 transition-all ease-in-out'
                    : 'absolute left-0 top-[0.25px] p-2 transition-all ease-in-out'
                }`}
              >
                {label}
              </p>
            </div>
            <TextFieldStyle
              {...field}
              id={id}
              onClick={(e) => onHandleClick(e, field.onClick)}
              onBlur={(e) => onHandleBlur(e, field.onBlur)}
              value={value}
              width={width}
              height={height}
              placeholder={cliked ? '' : placeholder}
              error={error}
              className="text-black-main"
              type={showPassword ? 'text' : 'password'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(event.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{icon}</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      icon={
                        showPassword ? 'ic:round-remove-red-eye' : 'mdi:eye-off'
                      }
                      className={`text-[20px] cursor-pointer ${
                        error ? 'text-red-main' : 'hover:text-blue-main'
                      }`}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      </Field>
      {error && (
        <p
          id={`error-${name}`}
          className="text-[14px] text-red-main mt-[6px] text-start"
        >
          {errorText}
        </p>
      )}
    </div>
  ) : (
    <div className={`${style}`}>
      <Skeleton variant="rounded" width={width} height={height} />
    </div>
  )
}

export default PasswordBar
