import { atom } from 'recoil'

const user = atom({
  key: 'user',
  default: {
    firstname: '',
    imagePath: '',
    enterpriseName: '',
    enterpriseId: '',
  },
})

export { user }
