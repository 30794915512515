import React, { useState } from 'react'
import { useMutation } from 'urql'
import { Icon } from '@iconify/react'
import { useRecoilState } from 'recoil'
import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { ElementComponent, AlertComponent } from 'components'
import { useAuth } from 'stores/recoil'
import { general } from 'utils'
import { useResponsive } from 'hooks'
import { appSettings } from 'settings'
import { AuthMutate } from 'services/graphql/authService'
import { LoginType } from 'types'

const initialValues: LoginType.LoginDataType = {
  email: '',
  password: '',
}

const validationSchema = yup.object({
  email: yup.string().required('กรุณากรอกอีเมล'),
  password: yup.string().required('กรุณากรอกรหัสผ่าน'),
})

function LoginForm() {
  const { isMobile } = useResponsive()
  const [loginMutation, executeMutation] = useMutation(AuthMutate.login)
  const [, setToken] = useRecoilState(useAuth.selector.selectorToken)

  const [errorModalData, setErrorModalData] = useState({
    title: 'เข้าสู่ระบบไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'เข้าสู่ระบบไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  const loginUser = async (values: LoginType.LoginDataType, actions) => {
    try {
      actions.setStatus(undefined)
      await executeMutation({
        data: {
          email: values.email,
          password: values.password,
        },
      }).then((res) => {
        if (res.error) {
          const error: any = res.error
          const code: number = error.graphQLErrors[0].originalError.code

          if (code === 401) {
            actions.setStatus({
              identifyId: 'อีเมล หรือ รหัสผ่านไม่ถูกต้อง',
              password: 'อีเมล หรือ รหัสผ่านไม่ถูกต้อง',
            })
            return
          }

          if (code) {
            setErrorModalData({
              title: 'เข้าสู่ระบบไม่สำเร็จ',
              description: 'กรุณาทดลองใหม่อีกครั้ง',
              open: true,
            })
            return
          }
        }

        general.cookie.setCookie(
          appSettings.TOKEN_KEY,
          res.data.loginOperator.refreshToken,
        )
        setToken(res.data.loginOperator.accessToken)
      })
    } catch (error) {
      setErrorModalData({ ...errorModalData, open: true })
    }
  }

  return (
    <div className="w-full">
      <div className="text-center laptop:text-start flex flex-col gap-[20px]">
        <p
          className="text-[24px] laptop:text-[32px] font-bold text-blue-main"
          id="login-title-message"
        >
          เข้าสู่ระบบ
        </p>
        <p className="text-[18px] laptop:text-[24px]">
          ยินดีต้อนรับสู่ DR. ASA Health and Wellness
        </p>
      </div>

      <hr className="border-t-[1px] border-dashed border-gray-main mt-[25px] mb-[42px]" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={loginUser}
      >
        {(formik) => (
          <Form
            onChange={() => {
              formik.setStatus(undefined)
            }}
          >
            <div className="flex flex-col">
              <ElementComponent.InputBar
                label="อีเมล"
                id="id-input-login"
                name="identifyId"
                placeholder="อีเมล"
                width={isMobile ? '100%' : '470px'}
                height="60px"
                style="mb-[30px]"
                value={formik.values.email}
                errorText={
                  formik.status && formik.status.email
                    ? formik.status.email
                    : formik.errors.email
                }
                error={
                  (formik.status && formik.status.identifyId) ||
                  (formik.touched.email && Boolean(formik.errors.email))
                }
                onChange={(text: string) => formik.setFieldValue('email', text)}
                frontIcon={
                  <Icon
                    icon="teenyicons:id-solid"
                    className={`text-[20px] ${
                      (formik.status && formik.status.identifyId) ||
                      (formik.touched.email && Boolean(formik.errors.email))
                        ? 'text-red-light'
                        : 'text-trueGray-400'
                    } ml-[12px] mr-[8px]`}
                  />
                }
                isLoading={loginMutation.fetching}
              />
              <ElementComponent.PasswordBar
                name="password"
                id="password-input-login"
                label="รหัสผ่าน"
                placeholder="รหัสผ่าน"
                width={isMobile ? '100%' : '470px'}
                height="60px"
                errorText={
                  formik.status && formik.status.password
                    ? formik.status.password
                    : formik.errors.password
                }
                error={
                  (formik.status && formik.status.password) ||
                  (formik.touched.password && Boolean(formik.errors.password))
                }
                value={formik.values.password}
                onChange={(text: string) =>
                  formik.setFieldValue('password', text)
                }
                icon={
                  <Icon
                    icon="material-symbols:lock"
                    className={`text-[20px] ${
                      (formik.status && formik.status.password) ||
                      (formik.touched.password &&
                        Boolean(formik.errors.password))
                        ? 'text-red-light'
                        : 'text-trueGray-400'
                    }  ml-[12px] mr-[8px]`}
                  />
                }
                isLoading={loginMutation.fetching}
              />
            </div>

            <div className="mt-[32px] w-full laptop:w-[470px]">
              <ElementComponent.Button
                loading={loginMutation.fetching}
                id="login-submit-button"
                submit
                text="เข้าสู่ระบบ"
                width="100%"
                height={isMobile ? '60px' : '52px'}
              />
            </div>
          </Form>
        )}
      </Formik>

      <AlertComponent.ErrorModal
        title={errorModalData.title}
        description={errorModalData.description}
        open={errorModalData.open}
        onClose={onCloseErrorModal}
      />
    </div>
  )
}

export default LoginForm
