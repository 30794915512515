import React, { useState } from 'react'
import { useMutation } from 'urql'
import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { ElementComponent, AlertComponent } from 'components'
import { AffinityMutation } from 'services/graphql/affinitySevice'

interface EnterpriseTreatmentFormInterface {
  etptId: string
  enterpriseHn: string
}

interface EnterpriseTreatmentModalInterface {
  open: boolean
  onClose: Function
  etptId: string
  name: string
  citizenId: string
  phnoeNumber: string
}

const validationSchema = yup.object({
  etptId: yup.string(),
  enterpriseHn: yup
    .string()
    .required('กรุณากรอกเลข HN ของคนไข้ ในสถานพยาบาลของท่าน'),
})

function EnterpriseTreatmentModal({
  open,
  onClose,
  etptId,
  name,
  citizenId,
  phnoeNumber,
}: Readonly<EnterpriseTreatmentModalInterface>) {
  const [, executeUpdateEnterpriseTreatmentPlaceTransactionMutation] =
    useMutation(AffinityMutation.updateStatus)

  const [successModalState, setSuccessModalState] = useState(false)

  const handleClose = () => {
    onClose()
  }

  const onSubmit = async (value: EnterpriseTreatmentFormInterface) => {
    const splitHN = value.enterpriseHn.split('/')
    const processHn = `${splitHN[1]}${splitHN[0].padStart(7, '0')}`

    await executeUpdateEnterpriseTreatmentPlaceTransactionMutation({
      where: {
        etptId: value.etptId,
      },
      data: {
        enterprise_hnId: {
          set: processHn,
        },
      },
    })

    handleClose()
    setSuccessModalState(true)
  }

  return (
    <>
      <ElementComponent.ModalForm open={open} onClose={() => handleClose()}>
        <div className="flex flex-col items-center w-screen max-w-[550px] max-h-[700px]">
          <Formik
            initialValues={{
              etptId: etptId,
              enterpriseHn: '',
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} className="w-full">
                <div className="p-[20px]">
                  <p className="text-[20px] font-bold text-start w-full">
                    กรอกรายละเอียด
                  </p>
                </div>

                <hr className="mb-[20px]" />

                <div className="mt-2 pl-5 flex flex-row">
                  <p className="text-10 font-bold text-start w-full">
                    ชื่อ-นามสกุล :
                  </p>
                  <p className="text-10 text-start w-full">{name}</p>
                </div>

                <div className="mt-2 pl-5 flex flex-row">
                  <p className="text-10 font-bold text-start w-full">
                    เลขบัตรประชาชน :
                  </p>
                  <p className="text-10 text-start w-full">{citizenId}</p>
                </div>

                <div className="mt-2 pl-5 flex flex-row">
                  <p className="text-10 font-bold text-start w-full">
                    เบอร์โทรศัพท์ :
                  </p>
                  <p className="text-10 text-start w-full">{phnoeNumber}</p>
                </div>

                <div className="px-[22px] pt-[30px] pb-[40px] gap-8">
                  <div className="flex flex-col gap-8 mb-3">
                    <ElementComponent.InputBar
                      id="update-status-enterprise-treatment-place"
                      isLoading={false}
                      label="เลข HN ของคนไข้"
                      placeholder="เลข HN ของคนไข้"
                      name="enterpriseHn"
                      focusSpacing={false}
                      height="52px"
                      width={'w-full'}
                      value={formik.values?.enterpriseHn || ''}
                      errorText={formik.errors.enterpriseHn}
                      error={
                        formik.touched.enterpriseHn &&
                        Boolean(formik.errors.enterpriseHn)
                      }
                      onChange={(text: string) =>
                        formik.setFieldValue('enterpriseHn', text)
                      }
                    />
                  </div>

                  <ElementComponent.Button
                    id="update-status-enterprise-treatment-place"
                    submit
                    text="ยืนยัน"
                    width="100%"
                    height="52px"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ElementComponent.ModalForm>
      <AlertComponent.SuccessModal
        title={`เชื่อมต่อข้อมูลสำเร็จ`}
        description={''}
        open={successModalState}
        onClose={() => setSuccessModalState(false)}
      />
    </>
  )
}

export default EnterpriseTreatmentModal
