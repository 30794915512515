import React from 'react'
import * as ReactDOMClient from 'react-dom/client'

import { RecoilRoot } from 'recoil'
import App from './App'
import './index.css'

const root = ReactDOMClient.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
)
