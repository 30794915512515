import React from 'react'
import { Grid, Skeleton } from '@mui/material'

export default function SpecificDataSkeleton() {
  return (
    <div className="bg-gray-100 rounded-[15px]">
      <div className="p-[20px]">
        <Skeleton width="40%" height="40px" />
      </div>
      <hr className="mb-[10px]" />
      <div className="p-[20px]">
        <Grid container columns={{ mobile: 1, laptop: 3 }} columnSpacing="5px">
          {Array(3)
            .fill(0)
            .map((_, index: number) => (
              <Grid key={`specific-data-${index}`} item mobile={1}>
                <Skeleton width="20%" />
                <Skeleton height="52px" />
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  )
}
