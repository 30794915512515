import React from 'react'
import { Icon } from '@iconify/react'

import { ElementComponent } from 'components'
import { RegisterType } from 'types'

export default function AuthForm({
  form,
  isLoading,
  require_phone,
}: RegisterType.FormProps) {
  return (
    <div className="flex flex-col gap-[20px]">
      <p className="text-[18px] font-bold mb-[30px]">
        ข้อมูลสำหรับการเข้าสู่ระบบ
      </p>
      {require_phone ? (
        <ElementComponent.InputBar
          id="phone-number-input"
          label="เบอร์โทรศัพท์"
          placeholder="เบอร์โทรศัพท์"
          name="phoneNumber"
          width="100%"
          height="50px"
          value={form.values.phoneNumber}
          errorText={form.errors.phoneNumber}
          error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
          onChange={(text: string) => form.setFieldValue('phoneNumber', text)}
          frontIcon={
            <Icon
              icon="ic:baseline-phone"
              className={`text-[20px] ${
                form.status?.identifyId ||
                (form.touched.identifyId && Boolean(form.errors.identifyId))
                  ? 'text-red-light'
                  : 'text-trueGray-400'
              } ml-[12px] mr-[8px]`}
            />
          }
          isLoading={isLoading}
        />
      ) : (
        <ElementComponent.InputBar
          id="identify-id-input"
          label="เลขใบประกอบวิชาชีพ"
          placeholder="เลขใบประกอบวิชาชีพ"
          name="identifyId"
          width="100%"
          height="50px"
          value={form.values.identifyId}
          errorText={form.errors.identifyId}
          error={form.touched.identifyId && Boolean(form.errors.identifyId)}
          onChange={(text: string) => form.setFieldValue('identifyId', text)}
          frontIcon={
            <Icon
              icon="teenyicons:id-solid"
              className={`text-[20px] ${
                form.status?.identifyId ||
                (form.touched.identifyId && Boolean(form.errors.identifyId))
                  ? 'text-red-light'
                  : 'text-trueGray-400'
              } ml-[12px] mr-[8px]`}
            />
          }
          isLoading={isLoading}
        />
      )}

      <ElementComponent.PasswordBar
        name="password"
        id="password-input"
        label="รหัสผ่าน"
        placeholder="รหัสผ่าน"
        width="100%"
        height="50px"
        value={form.values.password}
        errorText={form.errors.password}
        error={form.touched.password && Boolean(form.errors.password)}
        onChange={(text: string) => form.setFieldValue('password', text)}
        icon={
          <Icon
            icon="material-symbols:lock"
            className={`text-[20px] ${
              form.status?.password ||
              (form.touched.password && Boolean(form.errors.password))
                ? 'text-red-light'
                : 'text-trueGray-400'
            }  ml-[12px] mr-[8px]`}
          />
        }
        isLoading={isLoading}
      />
    </div>
  )
}
