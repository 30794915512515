import React from 'react'
import { Icon } from '@iconify/react'

interface UserOptionProps {
  title: string
  menu: any
  options: string
  onChangeOptions: Function
}

function UserOption({
  title,
  menu,
  options,
  onChangeOptions,
}: UserOptionProps) {
  return (
    <div
      className="bg-white-main rounded-[10px] fixed w-[300px] z-10 h-fit"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <p className="font-bold text-[18px] p-[28px]">{title}</p>

      <div className="border-t-[1px] border-gray-light py-[20px]">
        {Array.isArray(menu) &&
          menu.map((item, index: number) => (
            <div
              key={`user-option-${index}`}
              className={`flex items-center gap-[10px] cursor-pointer ${
                options === item.path ? 'text-blue-main' : ''
              } px-[30px] py-[10px] hover:bg-blue-light`}
              onClick={() => onChangeOptions(item.path)}
            >
              <Icon icon={item.icon} className="text-[18px]" />
              <p className="text-[18px]">{item.text}</p>
            </div>
          ))}
      </div>
    </div>
  )
}

export default UserOption
