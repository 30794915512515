import React from 'react'
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'

interface MenuHeaderBarProps {
  menuList: any
}

function MenuHeaderBar({ menuList }: MenuHeaderBarProps) {
  return (
    <Breadcrumbs
      className="text-[14px]"
      separator={
        <Icon
          icon="material-symbols:arrow-forward-ios-rounded"
          className="text-[14px] text-[#00000080]"
        />
      }
    >
      {menuList.map((item, index: number) => (
        <div
          className="flex items-center gap-[18px]"
          key={`header-list-${index}`}
        >
          <Link
            to={item.link}
            className={`text-[16px] ${
              index + 1 === menuList.length
                ? 'text-blue-main'
                : 'text-[#00000080]'
            }`}
          >
            {item.title}
          </Link>
        </div>
      ))}
    </Breadcrumbs>
  )
}

export default MenuHeaderBar
