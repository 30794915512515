import Cookies from 'js-cookie'

/**
 * It sets a cookie.
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to be stored in the cookie.
 * @param [option] - An object with the following properties:
 */
const setCookie = (name: string, value: string, expires?: Date) => {
  Cookies.set(name, value, {
    path: '/',
    sameSite: 'Strict',
    secure: true,
    expires: expires,
  })
}

/**
 * Get a cookie value by name
 * @param {string} name - The name of the cookie to retrieve.
 * @returns A string
 */
const getCookie = (name: string): string => {
  const value = Cookies.get(name)
  if (!value) return ''

  return value
}

/**
 * Remove a cookie from the browser
 * @param {string} name - The name of the cookie to remove.
 */
const removeCookie = (name: string) => {
  Cookies.remove(name)
}

export { setCookie, getCookie, removeCookie }
