const palette = {
  error: {
    main: '#E40009',
  },
  circularProgress: {
    '0': '#E40009',
    '1': '#F19701',
    '2': '#FBFF01',
    '3': '#CDD801',
    '4': '#69ae33',
  },
}

export default palette
