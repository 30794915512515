import React from 'react'
import { Icon } from '@iconify/react'

import { route } from 'settings'
import { useParams } from 'react-router-dom'
import { MenuComponent, FormComponent } from 'components'

export default function AddHealthDataManagementPage() {
  const { uId } = useParams()

  const menuList = [
    {
      title: 'ข้อมูลคนไข้',
      link: `${route.PATIENT_PAGE_URL}`,
    },
    {
      title: 'จัดการข้อมูลสุขภาพ',
      link: `${route.MEDICAL_DATA_PAGE_URL}/${uId}`,
    },
    { title: 'เพิ่ม', link: '' },
  ]
  return (
    <div className="laptop:ml-[245px] laptop:mr-[245px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />

      <div className="flex items-center gap-[13px] mt-[25px]">
        <Icon icon="mdi:heart-plus" className="text-[28px]" />
        <p className="text-[20px] font-bold">เพิ่มข้อมูลสุขภาพ</p>
      </div>

      <FormComponent.HealthForm.AddHealthForm />
    </div>
  )
}
