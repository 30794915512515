import { selector } from 'recoil'
import { tokenAtom } from 'stores/recoil/authentication/atom'

const selectorToken = selector({
  key: 'selectorToken',
  get: ({ get }) => get(tokenAtom).token,
  set: ({ set }, newValue) => {
    set(tokenAtom, { token: newValue })
  },
})

export { selectorToken }
