/**
 * SetData takes a string and a string and returns nothing.
 * @param {string} key - The key to store the data under.
 * @param {string} value - The value to be stored in the local storage.
 */
const setData = (key: string, value: string) => localStorage.setItem(key, value)

/**
 * GetData takes a string and returns a string.
 * @param {string} key - string - the key to store the data under
 */
const getData = (key: string): string => localStorage.getItem(key) || ''

/**
 * RemoveLocal takes a string and returns nothing.
 * @param {string} key - The key of the item you want to get from localStorage.
 */
const removeLocal = (key: string) => localStorage.removeItem(key)

/**
 * Clear the local storage
 */
const clearLocal = () => localStorage.clear()

export { setData, getData, removeLocal, clearLocal }
