import React, { useState, useEffect } from 'react'
import { useQuery } from 'urql'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import dayjs from 'dayjs'

import { SkeletonComponent } from 'components'
import { OverviewType } from 'types'
import { SummarizeQuery } from 'services/graphql/summarizeService'

interface BarChartOverviewDataProps {
  startDate: string
  endDate: string
  gap: OverviewType.SequenceType
  tableType: OverviewType.TableType
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function BarChartOverviewData({
  startDate,
  endDate,
  gap,
  tableType,
}: BarChartOverviewDataProps) {
  const [summarizeData] = useQuery(
    SummarizeQuery.getSummarizeDiagnoseData(
      gap.toLowerCase(),
      startDate as string,
      endDate as string,
      tableType === 'DIAGNOSED',
    ),
  )
  const [data, setData] = useState([])

  const configOptionChart = (
    type: OverviewType.TableType,
    sequence: OverviewType.SequenceType,
  ) => {
    const title = handleTitle(sequence)

    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: type === 'UNDIAGNOSED' ? 'จำนวนเคส' : 'จำนวนเคสที่วินิฉัย',
          align: 'start' as const,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: 20,
          yAlign: 'bottom' as const,
          titleAlign: 'center' as const,
          bodyAlign: 'center' as const,
          footerAlign: 'center' as const,
          footerColor: '#CDD800',
          titleFont: {
            size: 12,
          },
          bodyFont: {
            size: 16,
          },
          footerFont: {
            size: 20,
          },
          callbacks: {
            title: (data) => {
              const { label } = data[0]
              return `${sequence !== 'MONTH' ? `${title}ที่` : title} ${label}`
            },
            label: () => {
              return type === 'UNDIAGNOSED' ? 'จำนวนเคส' : 'จำนวนเคสที่วินิฉัย'
            },
            footer: (data) => {
              const { formattedValue } = data[0]
              return formattedValue
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            color: 'black',
            display: true,
            text: title,
            align: 'end' as const,
          },
        },
      },
    }
  }

  const preProcessData = (
    list: number[],
    type: OverviewType.TableType,
    sequence: OverviewType.SequenceType,
  ) => {
    return {
      labels: handleLabelsList(sequence, list),
      datasets: [
        {
          label: type === 'UNDIAGNOSED' ? 'จำนวนเคส' : 'จำนวนเคสที่วินิฉัย',
          data: handleDataList(list),
          backgroundColor: '#0D5CDD',
        },
      ],
    }
  }

  const handleLabelsList = (
    sequence: OverviewType.SequenceType,
    list: any[],
  ): any[] => {
    if (sequence === 'MONTH') {
      return list.map((item: any) => dayjs(item.date).format('MMMM BBBB'))
    } else if (sequence === 'WEEK') {
      return list.reduce((sum: string[], item: any) => {
        return [
          ...sum,
          `${Math.ceil(dayjs(item.date).date() / 7)} (${dayjs(item.date).format(
            'MMMM BBBB',
          )})`,
        ]
      }, [])
    } else {
      return list.map((item: any) => dayjs(item.date).format('DD MMMM BBBB'))
    }
  }

  const handleTitle = (sequence: OverviewType.SequenceType): string => {
    if (sequence === 'MONTH') return 'เดือน'
    else if (sequence === 'WEEK') return 'สัปดาห์'
    else return 'วัน'
  }

  const handleDataList = (list: any[]) => {
    return list.map((item: any) => item.total)
  }

  const handleInitialDate = (
    list: any[],
    sequence: OverviewType.SequenceType,
  ): any[] => {
    if (list.length === 1) {
      const [{ date }] = list
      const result = list

      result.unshift({
        date: dayjs(date)
          .subtract(
            sequence === 'WEEK' ? 7 : 1,
            sequence === 'MONTH' ? 'month' : 'day',
          )
          .toDate(),
        total: 0,
      })
      result.push({
        date: dayjs(date)
          .add(
            sequence === 'WEEK' ? 7 : 1,
            sequence === 'MONTH' ? 'month' : 'day',
          )
          .toDate(),
        total: 0,
      })

      return result
    } else return list
  }

  useEffect(() => {
    if (summarizeData.fetching || !summarizeData.data) return

    const { groupByDiagnosisDatas } = summarizeData.data
    setData(
      handleInitialDate(
        groupByDiagnosisDatas ? groupByDiagnosisDatas : [],
        gap,
      ),
    )
  }, [summarizeData.data])

  return (
    <div className="h-full">
      <div className="h-[500px] w-full">
        {!summarizeData.fetching && !summarizeData.error ? (
          <Bar
            options={configOptionChart(tableType, gap)}
            data={preProcessData(data, tableType, gap)}
            redraw={true}
          />
        ) : (
          <SkeletonComponent.BarChartSkeleton />
        )}
      </div>
    </div>
  )
}
