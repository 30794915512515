import React, { useState, useEffect } from 'react'
import { useQuery } from 'urql'
import { useNavigate, useParams } from 'react-router-dom'
import { Tooltip, Pagination } from '@mui/material'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Icon } from '@iconify/react'

import { MedicalQuery } from 'services/graphql/medicalService'
import { ElementComponent, SkeletonComponent, MenuComponent } from 'components'
import { route } from 'settings'
import { date } from 'utils'

export default function HealthDataManagementPage() {
  const { uId } = useParams()

  const menuList = [
    {
      title: 'ข้อมูลคนไข้',
      link: `${route.PATIENT_PAGE_URL}`,
    },
    { title: 'ข้อมูลสุขภาพ', link: '' },
  ]

  const navigate = useNavigate()
  const PAGE_SIZE = 10
  const topicTable = [
    'ชื่อ-นามสกุล',
    'วันที่บันทึกข้อมูล',
    'ผู้ที่บันทึกข้อมูล',
    'สถานพยาบาลที่บันทึกข้อมูล',
    'ตัวเลือก',
  ]

  const [pageAmount, setPageAmount] = useState<number>(1)
  const [page, setPage] = useState<number>(1)

  const [medicalTableData, setMedicalTableData] = useState<any[]>([])
  const [medicalData] = useQuery({
    ...MedicalQuery.getMedicalDatas(
      {
        patientUser: {
          is: {
            uId: {
              equals: uId,
            },
          },
        },
        dataInputFlag: {
          equals: true,
        },
      },
      PAGE_SIZE,
      (page - 1) * PAGE_SIZE,
    ),
  })

  const [medicalDataCount] = useQuery({
    ...MedicalQuery.countMedicalData({
      patientUser: {
        is: {
          uId: {
            equals: uId,
          },
        },
      },
    }),
  })

  const handleOnClickAddMedicalDataButton = () => {
    window.location.href = `${route.MEDICAL_DATA_PAGE_URL}/${uId}/create`
  }

  const option = (umdId: string) => {
    const StyleToolTip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#C9E2F4',
        color: '#1475BB',
        fontSize: 12,
      },
    })

    return (
      <div className="flex justify-center">
        <StyleToolTip title={`ดูข้อมูลสุขภาพ`} color="info">
          <Icon
            icon="material-symbols:article"
            className={`w-[28px] h-[28px] ccursor-pointer hover:text-blue-main`}
            onClick={() =>
              navigate(`${route.MEDICAL_DATA_PAGE_URL}/${uId}/${umdId}`)
            }
          />
        </StyleToolTip>
      </div>
    )
  }

  const formatData = (data) => {
    return data.map((item) => {
      let name = '-'
      let submitUser = '-'
      let enterpriseName = '-'

      if (item?.patientUser?.prefix)
        name = `${item.patientUser?.prefix || ''} ${
          item.patientUser?.firstname || ''
        } ${item.patientUser?.lastname || ''}`

      if (item?.submitUser?.prefix) {
        submitUser = `${item.submitUser?.prefix || ''} ${
          item.submitUser?.firstname || ''
        } ${item.submitUser?.lastname || ''}`

        enterpriseName =
          item.submitUser?.EnterpriseTreatmentPlaceTransaction[0]
            ?.enterpriseTreatmentPlace?.thaiName || '-'

        enterpriseName =
          item.submitUser.uId === item.patientUser.uId
            ? 'คนไข้ทำการบันทึกข้อมูลด้วยตนเอง'
            : enterpriseName
      }

      return {
        [topicTable[0]]: {
          data: name,
          color: 'black',
        },
        [topicTable[1]]: {
          data: date.convertToShowDateForGraph(item.createdAt),
          color: 'black',
        },
        [topicTable[2]]: {
          data: submitUser,
          color: 'black',
        },
        [topicTable[3]]: {
          data: enterpriseName,
          color: 'black',
        },
        [topicTable[4]]: {
          data: option(item.umdId),
          color: 'black',
        },
      }
    })
  }

  useEffect(() => {
    if (
      medicalData.fetching ||
      !medicalData.data ||
      medicalDataCount.fetching ||
      !medicalDataCount.data
    )
      return

    setPageAmount(
      Math.ceil(
        (medicalDataCount.data.getAggregateMedicalData?._count.umdId || 0) / 10,
      ),
    )
    setMedicalTableData(formatData(medicalData.data.getMedicalDatas || []))
  }, [medicalData, medicalDataCount])

  return (
    <div className="p-[80px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />

      <div className="flex items-center gap-[10px]">
        <Icon icon="tabler:report" className="w-[28px] h-[28px]" />
        <p className="text-[24px]">ข้อมูลสุขภาพ</p>
      </div>

      <div
        className="mt-[20px] px-[20px] py-[30px] rounded-[8px] bg-white-main"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="flex flex-row justify-between">
          <div className="mb-[24px]">
            <ElementComponent.Button
              id="submit-button"
              onAction={handleOnClickAddMedicalDataButton}
              icon="material-symbols:add"
              text={'สร้างข้อมูลสุขภาพ'}
              width="200px"
              height="40px"
            />
          </div>
        </div>

        {!medicalData?.fetching || !medicalDataCount.fetching ? (
          <div>
            <ElementComponent.Table col={topicTable} row={medicalTableData} />
            {medicalTableData.length === 0 && (
              <div className="my-[80px]">
                <p className="text-center text-[18px] text-black-lighter">
                  ไม่มีข้อมูลสุขภาพ
                </p>
              </div>
            )}
          </div>
        ) : (
          <SkeletonComponent.TableSkeleton columnsAmount={4} />
        )}

        {pageAmount !== 0 && (
          <div className="mt-[30px] flex justify-center">
            <Pagination
              page={page}
              count={pageAmount}
              variant="outlined"
              shape="rounded"
              onChange={(_, newPage: number) => setPage(newPage)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
