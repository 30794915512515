import React, { useState } from 'react'
import { TextField, Divider, TextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Icon } from '@iconify/react'

import { date } from 'utils'

interface DateRangePickerProps {
  range: {
    startDate: Date | string
    endDate: Date | string
  }
  onChange: Function
}

interface DateFieldProps {
  rangeDate: Date
  maxDate?: Date
  minDate?: Date
  placeholder: string
  onChange: Function
}

const DatePickerFieldStyle = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '46px',
    width: '200px',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    '& fieldset': {
      borderColor: '#E7E7E7',
      textAlign: 'center',
    },
    '&:hover fieldset': {
      borderColor: '#C9E2F4',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(20, 117, 187, 1)',
    },
    '&.Mui-disabled': {
      backgroundColor: '#F1F1F2',
      cursor: 'not-allowed',
      '& > fieldset': {
        borderColor: '#E7E7E7',
      },
    },
  },
})

function DateField({
  rangeDate,
  maxDate,
  minDate,
  placeholder,
  onChange,
}: DateFieldProps) {
  return (
    <div className="relative">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <p className="absolute top-[-8px] left-[10px] z-10 text-[12px] px-[5px] bg-white-main text-black-light">
          {placeholder}
        </p>
        <DatePicker
          disableFuture
          value={rangeDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={(newDate) => onChange(newDate)}
          renderInput={({ inputRef, InputProps }: TextFieldProps) => {
            return (
              <DatePickerFieldStyle
                inputRef={inputRef}
                placeholder={placeholder}
                value={rangeDate ? date.convertToShowDateThai(rangeDate) : ''}
                InputProps={InputProps}
              />
            )
          }}
          components={{
            OpenPickerIcon: () => (
              <Icon icon="clarity:date-line" className="text-[25px]" />
            ),
          }}
        />
      </LocalizationProvider>
    </div>
  )
}

export default function DateRangePicker({
  range,
  onChange,
}: DateRangePickerProps) {
  const [dateRange, setDateRange] = useState<any>({
    startDate: range.startDate,
    endDate: range.endDate,
  })

  const handleChangeEvent = (range: Date, type: 'START' | 'END') => {
    const playload = {
      ...(type === 'START' ? { startDate: range } : {}),
      ...(type === 'END' ? { endDate: range } : {}),
    }

    setDateRange((prev) => ({
      ...prev,
      ...playload,
    }))
    onChange((prev) => ({
      ...prev,
      ...playload,
    }))
  }

  return (
    <div>
      <div className="flex items-center gap-[10px]">
        <DateField
          placeholder="วันที่เริ่มต้น"
          maxDate={dateRange.endDate}
          rangeDate={dateRange.startDate}
          onChange={(playload: Date) => handleChangeEvent(playload, 'START')}
        />
        <Divider
          sx={{
            width: '10px',
            backgroundColor: '#000000',
            borderBottomWidth: '2px',
          }}
        />
        <DateField
          placeholder="วันที่สิ้นสุด"
          minDate={dateRange.startDate}
          rangeDate={dateRange.endDate}
          onChange={(playload: Date) => handleChangeEvent(playload, 'END')}
        />
      </div>
    </div>
  )
}
