import React from 'react'
import { Icon } from '@iconify/react'

import { ModalForm } from 'components/element'

interface ErrorModalProps {
  title: string
  description?: string
  open: boolean
  onClose: Function
}

function SuccessModal({ title, description, open, onClose }: ErrorModalProps) {
  return (
    <ModalForm open={open} onClose={() => onClose()}>
      <div className="flex flex-col items-center text-[20px] py-[52px] font-bold w-screen laptop:max-w-[532px] max-w-[450px]">
        <Icon
          icon="ep:success-filled"
          className="w-[70px] h-[70px] mb-[30px] text-green-main"
        />
        <p className="mb-[7px]">{title}</p>
        <p>{description}</p>
      </div>
    </ModalForm>
  )
}

export default SuccessModal
