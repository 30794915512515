import React, { useState, useEffect } from 'react'
import { useQuery } from 'urql'
import { Tooltip, Pagination } from '@mui/material'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Icon } from '@iconify/react'
import { Formik, Form } from 'formik'

import { date } from 'utils'
import { AffinityQuery } from 'services/graphql/affinitySevice'
import { ElementComponent, SkeletonComponent, ModalComponent } from 'components'

const dataInputFlagFilterSelect: ElementComponent.SelectBar.OptionSelectType[] =
  [
    { value: 'ทั้งหมด', key: 'null' },
    { value: 'รอการตรวจสอบ', key: 'WAITING' },
    { value: 'อนุมัติ', key: 'APPROVED' },
  ]

export default function AffinityPage() {
  const PAGE_SIZE = 10
  const [pageAmount, setPageAmount] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const [filterDataTable, setFilterDataTable] = useState<{
    searchText: string[]
    state: string | null
  }>({
    searchText: [],
    state: null,
  })

  const topicTable = [
    'HN (DR.ASA)',
    'HN (สถานพยาบาล)',
    'ชื่อ-นามสกุล',
    'เลขบัตรประชาชน',
    'เบอร์โทรศัพท์',
    'ขอเชื่อมต่อข้อมูลเมื่อ',
    'สถานะ',
    'ตัวเลือก',
  ]

  const [enterpriseModalState, setEnterpriseModalState] = useState({
    open: false,
    etptId: '',
    name: '',
    citizenId: '',
    phnoeNumber: '',
  })

  const [userData] = useQuery({
    ...AffinityQuery.gets(
      {
        ...(filterDataTable.searchText.length > 0
          ? {
              OR: filterDataTable.searchText,
            }
          : {}),
        ...(filterDataTable.state !== null
          ? {
              status: {
                equals: filterDataTable.state,
              },
            }
          : {}),
      },
      PAGE_SIZE,
      (page - 1) * PAGE_SIZE,
    ),
  })
  const [userTableData, setUserTableData] = useState<any[]>([])

  const [userDataCount] = useQuery({
    ...AffinityQuery.count({
      ...(filterDataTable.searchText.length > 0
        ? {
            OR: filterDataTable.searchText,
          }
        : {}),
      ...(filterDataTable.state !== null
        ? {
            status: {
              equals: filterDataTable.state,
            },
          }
        : {}),
    }),
  })

  const formatData = (data) => {
    return data.map((item) => {
      const MAP_STATUS_DISPLAY = {
        WAITING: {
          text: 'รอการตรวจสอบ',
          color: 'yellow-400',
        },
        APPROVED: {
          text: 'อนุมัติ',
          color: 'green-main',
        },
      }
      const hn_drasa = `${Array(7 - item.user.hnId.toString().length)
        .fill(0)
        .join('')}${item.user.hnId}`

      const name = `${item.user.prefix} ${item.user.firstname} ${item.user.lastname}`
      return {
        [topicTable[0]]: {
          data: hn_drasa,
          color: 'black',
        },
        [topicTable[1]]: {
          data: item.enterprise_hnId,
          color: 'black',
        },
        [topicTable[2]]: {
          data: name,
          color: 'black',
        },
        [topicTable[3]]: {
          data: item.citizenId,
          color: 'black',
        },
        [topicTable[4]]: {
          data: item.user.UserAuth.phoneNumber,
          color: 'black',
        },
        [topicTable[5]]: {
          data: date.convertToShowDateTime(item.createdAt),
          color: 'black',
        },
        [topicTable[6]]: {
          data: MAP_STATUS_DISPLAY[item.status].text,
          color: MAP_STATUS_DISPLAY[item.status].color,
        },
        [topicTable[7]]: {
          data: option(
            item.etptId,
            name,
            item.citizenId,
            item.user.UserAuth.phoneNumber,
            item.status === 'APPROVED',
          ),
          color: 'black',
        },
      }
    })
  }

  const option = (
    etptId: string,
    name: string,
    citizenId: string,
    phnoeNumber: string,
    disable = true,
  ) => {
    const StyleToolTip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#C9E2F4',
        color: '#1475BB',
        fontSize: 12,
      },
    })

    return (
      <div className="flex justify-center">
        <StyleToolTip title="แก้ไขข้อมูลขอการเชื่อมต่อ" color="info">
          <Icon
            icon="mdi:pencil"
            className={`w-[28px] h-[28px] ${
              disable
                ? 'cursor-not-allowed text-gray-main'
                : 'cursor-pointer hover:text-blue-main'
            }`}
            onClick={() =>
              !disable &&
              setEnterpriseModalState({
                open: true,
                etptId: etptId,
                name: name,
                citizenId: citizenId,
                phnoeNumber: phnoeNumber,
              })
            }
          />
        </StyleToolTip>
      </div>
    )
  }

  const handleSearchData = () => {
    setPage(1)
  }

  useEffect(() => {
    if (
      userData.fetching ||
      !userData.data ||
      userDataCount.fetching ||
      !userDataCount.data
    )
      return

    setPageAmount(
      Math.ceil(
        (userDataCount.data.countEnterpriseTreatmentPlaceTransaction || 0) / 10,
      ),
    )
    const save_data = formatData(
      userData.data.getEnterpriseTreatmentPlaceTransactions || [],
    )
    setUserTableData(save_data)
  }, [userData])

  useEffect(() => {
    setPage(1)
  }, [filterDataTable])

  return (
    <div className="p-[80px]">
      <div className="flex items-center gap-[10px]">
        <Icon
          icon="heroicons:document-chart-bar-solid"
          className="w-[28px] h-[28px]"
        />
        <p className="text-[24px]">จัดการข้อมูลขอเชื่อมต่อกับสถานพยาบาล</p>
      </div>

      <div
        className="mt-[20px] px-[20px] py-[30px] rounded-[8px] bg-white-main"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="flex flex-row justify-between">
          <div className="mb-[24px]">
            <Formik
              initialValues={{ text: '', dataFlag: 'null' }}
              onSubmit={() => handleSearchData()}
            >
              {(formik) => (
                <Form>
                  <div className="flex gap-[5px]">
                    <ElementComponent.InputBar
                      id="search-data-table"
                      name="text"
                      focusSpacing={false}
                      isLoading={false}
                      placeholder="ค้นหา ชื่อผู้ใช้งาน หรือ เบอร์โทรศัพท์"
                      height="40px"
                      frontIcon={
                        <Icon
                          icon="material-symbols:search-rounded"
                          className="w-[20px] h-[20px]"
                        />
                      }
                      value={formik.values.text}
                      onChange={(text: string) => {
                        formik.setFieldValue('text', text)
                        const search_keys = text.split(' ')
                        const search = []
                        search_keys.forEach((key) => {
                          search.push({
                            user: {
                              is: {
                                firstname: {
                                  contains: key,
                                },
                              },
                            },
                          })
                          search.push({
                            user: {
                              is: {
                                lastname: {
                                  contains: key,
                                },
                              },
                            },
                          })
                          search.push({
                            user: {
                              is: {
                                UserAuth: {
                                  is: {
                                    phoneNumber: {
                                      contains: key,
                                    },
                                  },
                                },
                              },
                            },
                          })
                        })
                        setFilterDataTable({
                          ...filterDataTable,
                          searchText: search,
                        })
                      }}
                    />

                    <ElementComponent.SelectBar.SelectBar
                      label="สถานะ"
                      placeholder="สถานะ"
                      focusSpacing={false}
                      name="state"
                      isLoading={false}
                      width={'w-full'}
                      height="h-[37px]"
                      data={formik.values.dataFlag}
                      option={dataInputFlagFilterSelect}
                      onChange={(dataFlag: string) => {
                        formik.setFieldValue('dataFlag', dataFlag)
                        if (dataFlag === 'null') dataFlag = null
                        setFilterDataTable({
                          ...filterDataTable,
                          state: dataFlag,
                        })
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {!userData?.fetching ? (
          <>
            <ElementComponent.Table col={topicTable} row={userTableData} />
            {userTableData.length === 0 && (
              <div className="my-[80px]">
                <p className="text-center text-[18px] text-black-lighter">
                  ไม่มีข้อมูลคนผู้ใช้งาน
                </p>
              </div>
            )}
          </>
        ) : (
          <SkeletonComponent.TableSkeleton columnsAmount={4} />
        )}

        {pageAmount !== 0 && (
          <div className="mt-[30px] flex justify-center">
            <Pagination
              page={page}
              count={pageAmount}
              variant="outlined"
              shape="rounded"
              onChange={(_, newPage: number) => setPage(newPage)}
            />
          </div>
        )}
      </div>
      <ModalComponent.EnterpriseTreatmentModal
        open={enterpriseModalState.open}
        onClose={() =>
          setEnterpriseModalState({
            open: false,
            etptId: '',
            name: '',
            citizenId: '',
            phnoeNumber: '',
          })
        }
        name={enterpriseModalState.name}
        citizenId={enterpriseModalState.citizenId}
        phnoeNumber={enterpriseModalState.phnoeNumber}
        etptId={enterpriseModalState.etptId}
      />
    </div>
  )
}
