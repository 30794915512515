import React from 'react'

import { LoginForm } from 'components/form'
import { LoginSVG, Logo2SVG } from 'assets/svg'

function LoginPage() {
  return (
    <div className="flex h-screen">
      <div className="w-1/2 text-white-main text-center flex flex-col justify-center items-center bg-blue-main">
        <div className="mb-[80px] scale-1.5">
          <Logo2SVG />
        </div>
        <LoginSVG />
        <p className="text-[28px] mt-[60px] p-[20px]">
          DR. ASA HEALTH AND WELLNESS
        </p>
      </div>

      <div className="w-1/2 text-center p-[57px] bg-white-dark">
        <LoginForm />
      </div>
    </div>
  )
}

export default LoginPage
