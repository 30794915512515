import { atom } from 'recoil'

const swipeableDrawerLoginStateAtom = atom({
  key: 'swipeableDrawerLogin',
  default: {
    state: false,
  },
})

const swipeableDrawerMenuStateAtom = atom({
  key: 'swipeableDrawerMenu',
  default: {
    state: false,
  },
})

const diagnosisTabStateAtom = atom({
  key: 'diagnosisTab',
  default: {
    state: 'NOT_DIAGNOSE',
  },
})

export {
  swipeableDrawerLoginStateAtom,
  swipeableDrawerMenuStateAtom,
  diagnosisTabStateAtom,
}
