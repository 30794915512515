const GRAPHQL_DATA_URL = `${process.env.REACT_APP_SERVER_URL}/api/data/graphql`

//* External APIs
const PROVINCE_DATA_URL =
  'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json'

const DISTRICT_DATA_URL =
  'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json'

export { GRAPHQL_DATA_URL, PROVINCE_DATA_URL, DISTRICT_DATA_URL }
