import _ from 'lodash'

import { route, appSettings } from 'settings/index'
import { general } from 'utils'
import { clearLocal } from './localStorage'

/**
 * It sets a cookie with the name "token" and the value of the token.
 * @param {string} token - The token to be set.
 */
const setRefreshToken = (token: string) => {
  general.cookie.setCookie(appSettings.TOKEN_KEY, token)
}

/**
 * Get the token from the cookie
 * @returns A string
 */
const getRefreshToken = (): string => {
  const token = general.cookie.getCookie(appSettings.TOKEN_KEY)
  if (!token) return ''
  return token
}

/**
 * Returns true if the user is authorized, false otherwise.
 * @returns A boolean value.
 */
const isAuthorized = (): boolean => {
  return !_.isEmpty(getRefreshToken())
}

/**
 * If the user is logged in, remove the token from the browser and redirect to the login page
 */
const logout = () => {
  if (isAuthorized()) {
    general.cookie.removeCookie(appSettings.TOKEN_KEY)
    clearLocal()
  }
  general.http.goto(route.LOGIN_PAGE_URL)
}

export { setRefreshToken, getRefreshToken, isAuthorized, logout }
