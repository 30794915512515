import React, { useState, useEffect } from 'react'
import { Field } from 'formik'
import { Autocomplete, TextField, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'

// import { ElementComponent } from 'components'

export type OptionsType = {
  key: string
  value: string
}

interface AutocompleteOption {
  label: string
  value: string
}

interface MultiSelectBarProps {
  options: OptionsType[]
  data: OptionsType[]
  name: string
  focusSpacing?: boolean
  height: string
  label: string
  placeholder: string
  isLoading: boolean
  disabled?: boolean
  error?: boolean
  onSelect: Function
}

interface TextFieldStyleProps {
  height: string
  width: string
  error: boolean
  disabled: boolean
}

const TextFieldStyle = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'width' && prop !== 'error',
})<TextFieldStyleProps>(({ height, width, error, disabled }) => {
  let border_color_hover = 'rgba(201, 226, 244, 1)'
  if (error) border_color_hover = 'rgba(228, 0, 9, 1)'
  if (disabled) border_color_hover = '#E7E7E7'

  return {
    '& .MuiOutlinedInput-root': {
      height: height,
      width: width,
      backgroundColor: '#FFFFFF',
      borderRadius: '5px',
      '& fieldset': {
        borderColor: error ? 'rgba(228, 0, 9, 1)' : '#E7E7E7',
      },
      '&:hover fieldset': {
        borderColor: border_color_hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: error ? 'rgba(228, 0, 9, 1)' : 'rgba(20, 117, 187, 1)',
      },
      '&.Mui-disabled': {
        backgroundColor: '#F1F1F2',
        '& > fieldset': {
          borderColor: '#E7E7E7',
        },
      },
    },
    '& input::placeholder': {
      fontSize: '16px',
    },
  }
})

export default function MultiSelectBar({
  name,
  options,
  data,
  focusSpacing = true,
  height,
  label,
  placeholder,
  isLoading = false,
  disabled = false,
  error = false,
  onSelect,
}: MultiSelectBarProps) {
  const [cliked, setCliked] = useState(false)

  const onHandleClick = (e, defaultOnClick) => {
    setCliked(true)

    if (defaultOnClick) defaultOnClick(e)
  }

  const onHandleBlur = (e, defaultOnblur) => {
    setCliked(false)

    if (defaultOnblur) defaultOnblur(e)
  }

  const handleOption = (option: OptionsType[] | null): AutocompleteOption[] => {
    if (option !== null) {
      return option?.map((item: OptionsType) => ({
        label: item.key,
        value: item.value,
      }))
    } else return []
  }

  const handleCheckOption = (option: AutocompleteOption, value: any) => {
    return option.label === value.label
  }

  const selectOptionByData = (
    option: OptionsType[],
    value: AutocompleteOption[],
  ) => {
    return option.filter((item: OptionsType) =>
      value.some(
        (valueData: AutocompleteOption) => valueData.label === item.key,
      ),
    )
  }

  useEffect(() => {
    if (!disabled && error && isLoading) {
      const element = document.getElementById(`error-${name}`)
      if (element) {
        element.scrollIntoView({ block: 'center' })
        element.focus({ preventScroll: true })
      }
    }
  }, [disabled, error, isLoading])

  return (
    <div
      className={
        focusSpacing && ((!disabled && cliked) || Boolean(data))
          ? 'mt-[20px] transition-all ease-in-out'
          : ''
      }
    >
      {isLoading ? (
        <Field>
          {({ field }) => (
            <div>
              <div className="relative w-fit">
                <p
                  className={`text-black-main text-[16px] w-auto break-normal whitespace-nowrap text-left pl-1 font-bold ${
                    (!disabled && cliked) || Boolean(data)
                      ? 'absolute bottom-[0.25px] duration-300 delay-100 transition-all ease-in-out'
                      : 'absolute top-0.25 p-2 transition-all ease-in-out'
                  }`}
                >
                  {label}
                </p>
              </div>

              <Autocomplete
                {...field}
                multiple
                id={name}
                name={name}
                value={handleOption(data)}
                options={handleOption(options)}
                getOptionLabel={(option: AutocompleteOption) => option.value}
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: string,
                ) => handleCheckOption(option, value)}
                disableCloseOnSelect
                renderInput={(params: any) => (
                  <TextFieldStyle
                    {...params}
                    variant="outlined"
                    placeholder={placeholder}
                    width="100%"
                    height="100%"
                    error={error}
                    disabled={disabled}
                    onBlur={(e) => onHandleBlur(e, params.onBlur)}
                    onClick={(e) => onHandleClick(e, params.onClick)}
                  />
                )}
                onChange={(_, value: AutocompleteOption[]) => {
                  if (value !== null) {
                    onSelect(selectOptionByData(options, value))
                  }
                }}
              />
            </div>
          )}
        </Field>
      ) : (
        <Skeleton variant="rectangular" height={height} />
      )}
    </div>
  )
}
