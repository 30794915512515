import React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon } from '@iconify/react'

import { route } from 'settings'
import { Logo2SVG } from 'assets/svg'

interface MenuItemProps {
  title: string
  icon: string
  urlPath: string
}

const menuList = [
  {
    title: 'ข้อมูลคนไข้',
    icon: 'material-symbols:person',
    urlPath: route.PATIENT_PAGE_URL,
  },
  {
    title: 'สถานพยาบาล',
    icon: 'carbon:hospital',
    urlPath: route.AFFINITY_PAGE_URL,
  },
  { title: 'ตั้งค่า', icon: 'uiw:setting', urlPath: '/setting' },
]

function MenuItem({ title, icon, urlPath }: MenuItemProps) {
  return (
    <NavLink
      to={urlPath}
      className={({ isActive }) =>
        `flex items-center px-[20px] p-[10px] gap-[10px] ${
          isActive
            ? 'bg-blue-light text-blue-main'
            : 'text-white-main hover:bg-sky-700 hover:text-blue-light'
        }`
      }
    >
      <Icon icon={icon} className="w-[25px] h-[25px]" />
      <p className="text-[18px]">{title}</p>
    </NavLink>
  )
}

export default function ClinicNavbar() {
  return (
    <div className="w-[300px] h-full fixed top-0 left-0 z-[999] bg-blue-main">
      <div className="pt-[20px] px-[25px]">
        <Logo2SVG className="w-24 h-24" />
      </div>

      <div className="mt-[50px] flex flex-col gap-[10px]">
        {menuList.map((item: any, index: number) => (
          <MenuItem
            key={`doctor-menu-${index}`}
            title={item.title}
            icon={item.icon}
            urlPath={item.urlPath}
          />
        ))}
      </div>

      <p className="text-[14px] text-white-main text-center absolute bottom-[25px] w-full">
        © 2022, All rights reserved
      </p>
    </div>
  )
}
