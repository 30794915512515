import React, { useState, useEffect } from 'react'
import { Button, Divider } from '@mui/material'
import { useQuery } from 'urql'
import { Icon } from '@iconify/react'
import dayjs from 'dayjs'

import { ChartComponent, ElementComponent } from 'components'
import { OverviewType } from 'types'
import { date } from 'utils'
import { SummarizeQuery } from 'services/graphql/summarizeService'

interface OverviewCardProps {
  text: string
  color: string
  count: number
  select: boolean
  onClick: Function
}

interface ButtonGroupProps {
  text: string
  selected: boolean
  position?: 'START' | 'END'
  onClick: Function
}

const cardConfig = [
  {
    text: 'Case ที่ยังไม่ได้ทำการวินิจฉัย',
    color: 'bg-red-main',
    name: 'UNDIAGNOSED',
    type: 'PROCESS',
  },
  {
    text: 'Case ที่วินิจฉัยเรียบร้อยแล้ว',
    color: 'bg-green-main',
    name: 'DIAGNOSED',
    type: 'COMPLETED',
  },
]

const buttonGroupConfig = [
  {
    id: 0,
    text: 'เดือน',
    position: 'START',
  },
  {
    id: 1,
    text: 'สัปดาห์',
  },
  {
    id: 2,
    text: 'วัน',
    position: 'END',
  },
]

const initialDateRange: OverviewType.DateRangeType = {
  startDate: dayjs().startOf('day').subtract(7, 'day').toDate(),
  endDate: dayjs().startOf('day').toDate(),
}

function OverviewCard({
  text,
  color,
  count,
  select,
  onClick,
}: OverviewCardProps) {
  return (
    <div
      className={`py-[40px] px-[30px] rounded-[10px] flex items-center gap-[50px] bg-white-main cursor-pointer ${
        select
          ? 'border-[3px] border-blue-main'
          : 'border-[3px] border-transparent'
      } transition-all ease-in-out`}
      style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
      onClick={() => onClick()}
    >
      <p className="text-[20px] font-bold">{text}</p>
      <div
        className={`rounded-full ${color} p-[5px] flex justify-center items-center w-[40px] h-[40px]`}
      >
        <p className="text-white-main">{count}</p>
      </div>
    </div>
  )
}

function ButtonGroup({ text, selected, position, onClick }: ButtonGroupProps) {
  return (
    <Button
      variant={selected ? 'contained' : 'text'}
      sx={{
        height: '45px',
        width: '100px',
        borderRadius: '0px',
        ...(position !== 'END' ? { borderRight: '1px solid #C9E2F4' } : {}),
        ...(position !== 'START' ? { borderLeft: '1px solid #C9E2F4' } : {}),
      }}
      onClick={() => onClick()}
    >
      <p
        className={`${
          selected ? 'text-white-main' : 'text-blue-main'
        } text-[16px]`}
      >
        {text}
      </p>
    </Button>
  )
}

function OverviewPage() {
  const [group, setGroup] = useState<number>(0)
  const [range, setRange] =
    useState<OverviewType.DateRangeType>(initialDateRange)
  const [table, setTable] = useState<OverviewType.TableType>('UNDIAGNOSED')
  const [dataCount, setDataCount] = useState<OverviewType.diagnoseCountType>({
    completed: 0,
    process: 0,
  })

  const [diagnosedCount] = useQuery(SummarizeQuery.getDiagnosedCount())
  const [undiagnosedCount] = useQuery(SummarizeQuery.getUndiagnosedCount())

  const handleBarLength = (mode: number): OverviewType.SequenceType => {
    if (mode === 0) return 'MONTH'
    else if (mode === 1) return 'WEEK'
    else return 'DAY'
  }

  useEffect(() => {
    if (
      diagnosedCount.fetching ||
      !diagnosedCount.data ||
      undiagnosedCount.fetching ||
      !undiagnosedCount.data
    )
      return

    const diagnosed = diagnosedCount.data
    const undiagnosed = undiagnosedCount.data
    setDataCount({
      completed: diagnosed.getAggregateMedicalData?._count.umdId || 0,
      process: undiagnosed.getAggregateMedicalData?._count.umdId || 0,
    })
  }, [diagnosedCount.data, undiagnosedCount.data])

  return (
    <div className="p-[40px]">
      <div className="flex items-center gap-[10px]">
        <Icon icon="material-symbols:pie-chart" className="text-[30px]" />
        <p className="text-[24px] font-bold">ภาพรวม</p>
      </div>

      <div className="flex items-center gap-[20px] my-[40px]">
        {cardConfig.map((item: any, index: number) => (
          <OverviewCard
            key={`overview-card-${index}`}
            text={item.text}
            color={item.color}
            count={
              item.type === 'COMPLETED'
                ? dataCount.completed
                : dataCount.process
            }
            select={item.name === table}
            onClick={() => setTable(item.name)}
          />
        ))}
      </div>

      <div
        className="rounded-[10px] bg-white-main"
        style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
      >
        <div className="flex items-center justify-between rounded-[10px] py-[30px] px-[25px]">
          <p className="text-[20px] font-bold">
            {table === 'UNDIAGNOSED'
              ? 'จำนวน Case ทั้งหมดที่ได้รับมา'
              : 'จำนวน Case ที่วินิจฉัยเรียบร้อย'}
          </p>
          <div className="flex items-center gap-[10px]">
            <ElementComponent.DateRangePicker
              range={range}
              onChange={setRange}
            />
            <div className="border-[1.7px] border-blue-main rounded-[8px]">
              {buttonGroupConfig.map((item: any) => (
                <ButtonGroup
                  key={`button-group-${item.id}`}
                  text={item.text}
                  position={item.position}
                  selected={group === item.id}
                  onClick={() => setGroup(item.id)}
                />
              ))}
            </div>
          </div>
        </div>
        <Divider sx={{ marginBottom: '20px' }} />
        <div className="pt-[20px] pb-[40px] px-[30px]">
          <ChartComponent.BarChartOverviewData
            startDate={date.subtractByHour(range.startDate, 17)}
            endDate={date.subtractByHour(range.endDate, 17)}
            gap={handleBarLength(group)}
            tableType={table}
          />
        </div>
      </div>
    </div>
  )
}

export default OverviewPage
