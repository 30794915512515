export const getUser = () => {
  const getUserQuery = `query getUser ($where: UserWhereUniqueInput!) {
                          getUser (where: $where) {
                            prefix
                            firstname
                            lastname
                            imagePath
                            UserAuth {
                              phoneNumber
                            }
                            UserMedicalDemograhicData {
                              gender
                              birthdate
                              province
                              district
                            }
                          }
                        }`

  return {
    query: getUserQuery,
    variables: {
      where: {
        uId: '',
      },
    },
  }
}

export const getUserPatientUpdate = (uId) => {
  const getUserQuery = `query getUser ($where: UserWhereUniqueInput!) {
                          getUser (where: $where) {
                            prefix
                            firstname
                            lastname
                            imagePath
                            UserMedicalDemograhicData {
                              gender
                              birthdate
                              province
                              district
                            }
                            UserAuth {
                              phoneNumber
                              identifyId
                              email
                            }
                          }
                        }`
  return {
    query: getUserQuery,
    variables: {
      where: {
        uId: uId,
      },
    },
  }
}

export const getUserPatientShort = (uId) => {
  const getUserQuery = `query getUser ($where: UserWhereUniqueInput!) {
                          getUser (where: $where) {
                            uId
                            prefix
                            firstname
                            lastname
                            UserMedicalDemograhicData {
                              gender
                              birthdate
                            }
                            UserAuth {
                              phoneNumber
                            }
                          }
                        }`
  return {
    query: getUserQuery,
    variables: {
      where: {
        uId: uId,
      },
    },
  }
}

export const getUserProfile = () => {
  const getUserQuery = `query getUser ($where: UserWhereUniqueInput!) {
                          getUser (where: $where) {
                            firstname
                            imagePath
                            EnterpriseTreatmentPlaceTransaction {
                              enterpriseTreatmentPlace {
                                thaiName
                                ercId
                              }
                            }
                          }
                        }`

  return {
    query: getUserQuery,
    variables: {
      where: {
        uId: '',
      },
    },
  }
}

interface UserAuthFilter {
  identifyId?: {
    contains?: string
  }
  phoneNumber?: {
    contains?: string
  }
}

export const getUsers = (args: {
  where: {
    role: {
      is: {
        name: {
          equals: string
        }
      }
    }
    hnId?: {
      equals: number
    }
    UserAuth?: {
      is: {
        OR: UserAuthFilter[]
      }
    }
  }
  take?: number
  skip?: number
}) => {
  let getUserQuery = `query getUsers($where: UserWhereInput ,$take: Int, $skip: Int, $orderBy: [UserOrderByWithRelationInput!])  {
                          getUsers(where: $where, take: $take, skip: $skip, orderBy: $orderBy)  {
                              uId
                              hnId
                              prefix
                              firstname
                              lastname
                              UserMedicalDemograhicData {
                                birthdate
                                province
                                district
                              }
                              UserAuth {
                                identifyId
                              }
                              EnterpriseTreatmentPlaceTransaction {
                                enterpriseTreatmentPlace {
                                  thaiName
                                  ercId
                                }
                                enterprise_hnId
                              }
                              createdAt
                          }
                      }`

  let query: {
    query: string
    variables: {
      where: {
        role: {
          is: {
            name: {
              equals: string
            }
          }
        }
        hnId?: {
          equals: number
        }
        UserAuth?: {
          is: {
            OR: UserAuthFilter[]
          }
        }
      }
      take?: number
      skip?: number
      orderBy?: {
        createdAt: string
      }
    }
  } = {
    query: getUserQuery,
    variables: {
      where: {
        ...args.where,
      },
      orderBy: {
        createdAt: 'desc',
      },
    },
  }

  if (args.take)
    query = {
      ...query,
      variables: {
        ...query.variables,
        take: args.take,
      },
    }

  if (args.skip)
    query = {
      ...query,
      variables: {
        ...query.variables,
        skip: args.skip,
      },
    }

  return query
}

export const getCountUsers = (args: {
  where: {
    role: {
      is: {
        name: {
          equals: string
        }
      }
    }
  }
  take: number
  skip: number
}) => {
  const getUserQuery = `query aggregateUser($where: UserWhereInput, $_count: UserCountAggregateInput)  {
                            aggregateUser(where: $where, _count: $_count)  {
                                _count {
                                    uId
                                }
                            }
                        }`

  return {
    query: getUserQuery,
    variables: {
      where: {
        ...args.where,
      },
      _count: {
        uId: true,
      },
    },
  }
}
