import { selector } from 'recoil'

// import { general } from 'utils'
import { user } from 'stores/recoil/user/atom'

const seletorUser = selector({
  key: 'seletorUser',
  get: ({ get }) => get(user),
  set: ({ set }, newValue) => set(user, newValue),
})

export { seletorUser }
