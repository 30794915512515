import React, { useState, useEffect } from 'react'
import { Grid, Stack } from '@mui/material'
import { useQuery } from 'urql'
import { Icon } from '@iconify/react'
import * as yup from 'yup'
import dayjs from 'dayjs'

import { MedicalQuery } from 'services/graphql/medicalService'
import { useResponsive } from 'hooks'
import { ElementComponent, AlertComponent } from 'components'

interface DiseaseDataSpeicific {
  disease: {
    dId: string
    thaiName: string
  }
  date: Date
}

interface SpecificDiseaseFormProps {
  data?: DiseaseDataSpeicific
  loading?: boolean
  formData?: any
  disabled?: boolean
  onRemove?: Function
  onSetFormData?: Function
  onSetValidateForm?: Function
}

interface ImageFormProps {
  loading?: boolean
  disable: boolean
  medName: string
  title: string
  formData: any
  exampleImage?: string
}

function ImageForm({
  loading = false,
  disable,
  medName,
  title,
  formData,
  exampleImage,
}: Readonly<ImageFormProps>) {
  const { isMobile } = useResponsive()
  const [images, setImages] = useState(formData.values[medName]?.value || [])

  const removeImage = (index: number) => {
    let newObject = [...images]
    newObject.splice(index, 1)
    setImages(newObject)
    formData.setFieldValue(medName, newObject)
  }

  return (
    <div className="px-[10px] pb-[20px]">
      <p className="text-[16px] mb-[30px] text-black-lighter font-bold">
        {title}
      </p>
      <div className="flex flex-col gap-[50px]">
        {exampleImage !== '' && !disable && (
          <div>
            <p className="text-[16px] mb-[15px]">ตัวอย่างรูปที่ถูกต้อง</p>
            <Stack
              direction={{ mobile: 'column', laptop: 'row' }}
              gap="15px"
              className="items-center"
            >
              {exampleImage.split(',').map((imagePath: any, index: number) => (
                <div
                  className="w-full h-[300px] laptop:w-[300px] laptop:h-[210px]"
                  key={`exmaple-image-${index}`}
                >
                  <ElementComponent.Image
                    className="w-full h-full object-cover bg-center rounded-[10px]"
                    src={`${imagePath}`}
                    alt=""
                  />
                </div>
              ))}
            </Stack>
          </div>
        )}

        <div>
          {!disable && (
            <p className="text-[16px] font-bold mb-[15px]">อัพโหลดรูปภาพ</p>
          )}
          <Stack
            direction={{ mobile: 'column', laptop: 'row' }}
            gap="15px"
            className="items-center flex-wrap"
          >
            {images !== null &&
              images.map((item: any, index: number) => (
                <div
                  key={`image-upload-${index}`}
                  className="w-full h-[300px] laptop:w-[300px] laptop:h-[210px] relative"
                >
                  {!disable && (
                    <div
                      onClick={() => removeImage(index)}
                      className="absolute top-0 right-0 p-[5px] border-indigo-900 bg-blue-main hover:brightness-75 cursor-pointer rounded-tr-[10px]"
                    >
                      <Icon
                        icon="mdi:bin"
                        className="h-[25px] w-[25px] text-white-main"
                      />
                    </div>
                  )}
                  <ElementComponent.Image
                    className="h-full w-full object-cover bg-center rounded-[10px] border"
                    src={
                      typeof item === 'string'
                        ? item
                        : URL.createObjectURL(item)
                    }
                    alt=""
                  />
                </div>
              ))}

            {images.length < 3 && !disable && (
              <div
                className="bg-white-main laptop:w-fit w-full"
                style={{
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                }}
              >
                <ElementComponent.ImageButton
                  isLoading={loading}
                  id="image-upload-specific-disease"
                  width={isMobile ? '100%' : undefined}
                  height={isMobile ? '300px' : undefined}
                  text={
                    Array.isArray(images)
                      ? images.length >= 1
                        ? `${3 - images.length}/3`
                        : undefined
                      : undefined
                  }
                  onChange={(event) => {
                    if (event.target.files) {
                      setImages([...images, event.target.files[0]])
                      formData.setFieldValue(medName, {
                        ...formData.values[medName],
                        value: [
                          ...formData.values[medName].value,
                          event.target.files[0],
                        ],
                      })
                    }
                  }}
                />
              </div>
            )}
          </Stack>
        </div>
      </div>
    </div>
  )
}

function SpecificDiseaseForm({
  loading = false,
  data,
  formData,
  onRemove,
  onSetFormData,
  onSetValidateForm,
  disabled = false,
}: Readonly<SpecificDiseaseFormProps>) {
  const { isMobile } = useResponsive()
  const [attribute, setAttribute] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const query_diease = {
    disease: {
      is: {
        AND: [
          { dId: { equals: data.disease.dId } },
          { type: { equals: 'SPECIFIC' } },
        ],
      },
    },
  }
  const show_diease_attribute = disabled
    ? [query_diease]
    : [{ inputFlag: { equals: true } }, query_diease]

  const [diseaseAttibuteQueryData] = useQuery(
    MedicalQuery.getDiseaseAttibute({
      AND: show_diease_attribute,
    }),
  )

  const createValidataionSchema = (fields = []) => {
    const objectSchema = fields.reduce((schema, field) => {
      if (field.type === 'FLOAT') {
        schema[field.medName] = yup.object().shape({
          value: yup
            .number()
            .min(
              field.minValuePos,
              `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${field.maxValuePos} และ น้อยที่สุด ${field.minValuePos}`,
            )
            .max(
              field.maxValuePos,
              `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${field.maxValuePos} และ น้อยที่สุด ${field.minValuePos}`,
            )
            .typeError('กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง')
            .test(
              'check-2-precision',
              'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
              (value) => {
                if (value) {
                  return !!value.toString().match(/^\d+(\.\d{0,2})?$/)
                }
                return true
              },
            ),
        })
      }

      return schema
    }, {})

    return objectSchema
  }

  const createInitialValues = (fields = []) => {
    const objectValues = fields.reduce((list, field) => {
      if (field.type === 'FLOAT' || field.type === 'TEXT')
        list[field.medName] = {
          type: field.type,
          daId: field.daId,
          value: '',
          createdAt: dayjs(data.date).toISOString(),
        }
      else if (field.type === 'IMAGE')
        list[field.medName] = {
          type: field.type,
          daId: field.daId,
          value: [],
          createdAt: dayjs(data.date).toISOString(),
        }
      return list
    }, {})

    return objectValues
  }

  const handleDeleteForm = () => {
    onRemove()
    setOpenDeleteModal(false)
  }

  useEffect(() => {
    ;(async () => {
      if (!diseaseAttibuteQueryData.fetching) {
        const { getDiseasesAttibutes } = await diseaseAttibuteQueryData.data
        setAttribute(getDiseasesAttibutes)
      }
    })()
  }, [diseaseAttibuteQueryData])

  useEffect(() => {
    if (attribute.length) {
      onSetFormData && onSetFormData(createInitialValues(attribute))
      onSetValidateForm && onSetValidateForm(createValidataionSchema(attribute))
    }
  }, [attribute])

  return (
    <div className="rounded-[10px] bg-white-dark border">
      <div className="p-[17px] flex justify-between flex-wrap">
        <p className={`font-bold text-[${isMobile ? '20px' : '18px'}]`}>
          {`ค่าแลปของ${data.disease.thaiName}`}
        </p>

        <div className="flex items-center gap-[5px]">
          <Icon icon="material-symbols:date-range" className="text-[25px]" />
          <p>{`${dayjs(data.date).format('DD/MM/BBBB')}`}</p>

          {!disabled && (
            <Icon
              icon="mdi:bin"
              className={`text-[25px] ml-[10px] ${
                !loading && 'hover:text-red-main cursor-pointer'
              }`}
              onClick={() => !loading && setOpenDeleteModal(true)}
            />
          )}
        </div>
      </div>

      <hr className="mb-[25px]" />

      {attribute.length && (
        <Grid
          container
          alignItems="end"
          spacing="20px"
          columns={{ mobile: 1, laptop: 3 }}
          sx={{ padding: '17px', paddingTop: '0px' }}
        >
          {/*JSON.stringify(attribute)*/}
          {attribute.map(
            (item, index: number) =>
              item.type === 'FLOAT' && (
                <Grid key={`value-input-${index}`} item mobile={1}>
                  <ElementComponent.InputBar
                    id={`disease-${item?.medName}`}
                    disabled={disabled}
                    isLoading={loading}
                    disable_color="#000"
                    name={item.medName}
                    label={item.thaiName}
                    placeholder={disabled ? '' : item.thaiName}
                    height="50px"
                    backIcon={
                      <span className="text-[16px]">{item.unitThai}</span>
                    }
                    errorText={formData.errors[item.medName]?.value}
                    error={
                      formData.touched[item.medName] &&
                      Boolean(formData.errors[item.medName])
                    }
                    value={formData.values[item.medName]?.value}
                    onChange={(text: string) =>
                      formData.setFieldValue(item.medName, {
                        ...formData.values[item.medName],
                        value: text,
                      })
                    }
                  />
                </Grid>
              ),
          )}
        </Grid>
      )}

      {attribute.map((item) => {
        if (item.type === 'IMAGE') {
          return (
            <ImageForm
              key={`value-${item.medName}`}
              loading={loading}
              disable={disabled}
              medName={item.medName}
              title={item.thaiName}
              formData={formData}
              exampleImage={item.exampleImage}
            />
          )
        }
      })}

      {attribute.map((item) => {
        if (item.type === 'TEXT') {
          return (
            <div
              key={`disease-${item.medName}`}
              className="p-[10px] mt-[10px] mb-[50px]"
            >
              <ElementComponent.TextArea
                id={`disease-${item?.medName}`}
                isLoading={loading}
                disable={disabled}
                disable_color="#000"
                name={item.medName}
                label={item.thaiName}
                placeholder={item.thaiName}
                minlines={10}
                value={formData.values[item.medName]?.value}
                onChange={(value: string) => {
                  formData.setFieldValue(item.medName, {
                    ...formData.values[item.medName],
                    value: value,
                  })
                }}
              />
            </div>
          )
        }
      })}

      <AlertComponent.ConfirmModal
        icon="mdi:bin"
        text={`คุณต้องการลบข้อมูลเฉพาะทาง “${data.disease.thaiName}” ใช่หรือไม่ ?`}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        handleCancel={() => setOpenDeleteModal(false)}
        handleConfirm={() => handleDeleteForm()}
      />
    </div>
  )
}

export default SpecificDiseaseForm
