// Login Pages
const LOGIN_PAGE_URL = '/login'

// User Management Pages
const SETTING_PAGE_URL = '/setting'
const PROFILE_PAGE_URL = `${SETTING_PAGE_URL}/profile`
const CHANGE_PASSWORD_PAGE_URL = `${SETTING_PAGE_URL}/change-password`

// Overview Pages
const OVERVIEW_PAGE_URL = '/overview'

// Patient Pages
const PATIENT_PAGE_URL = '/user-management'
const CREATE_NEW_PATIENT = `${PATIENT_PAGE_URL}/patient`
const PATIENT_DATA_PAGE_URL = `${CREATE_NEW_PATIENT}/:uId`

// Medical data Pages
const MEDICAL_DATA_PAGE_URL = '/medical-data'
const MEDICAL_DATA_DATA_LIST_PAGE_URL = `${MEDICAL_DATA_PAGE_URL}/:uId`
const MEDICAL_DATA_DATA_LOOKUP_PAGE_URL = `${MEDICAL_DATA_DATA_LIST_PAGE_URL}/:umdId`
const MEDICAL_DATA_CREATE_PAGE_URL = `${MEDICAL_DATA_DATA_LIST_PAGE_URL}/create`

// Affinity Pages
const AFFINITY_PAGE_URL = '/affinity'

const DIAGNOSE_PAGE_URL = '/diagnose'
const DIAGNOSE_DATA_PAGE_URL = '/diagnose/:diagnoseId'

// ETC
const EXPECT_PAGE_URL = '*'

export {
  LOGIN_PAGE_URL,
  CHANGE_PASSWORD_PAGE_URL,
  PROFILE_PAGE_URL,
  OVERVIEW_PAGE_URL,
  SETTING_PAGE_URL,
  PATIENT_PAGE_URL,
  CREATE_NEW_PATIENT,
  PATIENT_DATA_PAGE_URL,
  MEDICAL_DATA_PAGE_URL,
  MEDICAL_DATA_DATA_LIST_PAGE_URL,
  MEDICAL_DATA_DATA_LOOKUP_PAGE_URL,
  MEDICAL_DATA_CREATE_PAGE_URL,
  AFFINITY_PAGE_URL,
  DIAGNOSE_PAGE_URL,
  DIAGNOSE_DATA_PAGE_URL,
  EXPECT_PAGE_URL,
}
