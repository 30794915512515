export const createDiagnoseResult = `
  mutation createDiagnosisData ($data: DiagnosisDataCreateCustomInput!) {
    createDiagnosisData (data: $data) {
        ddId
    }
  }
`

export const updateDiagnoseResult = `
  mutation updateDiagnosisData($data: DiagnosisDataUpdateCustomInput!, $where: DiagnosisDataWhereUniqueInput!) {
    updateDiagnosisData(data: $data, where: $where){
      ddId
    }
}`

export const updateUserInfo = `
  mutation updateUserInfo ($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserInfo (data: $data, where: $where) {
        uId
    }
  }
`

export const createMedicalData = `
mutation createMedicalData($data: UserMedicalDataCreateInput!, $files: [Upload!]) {
  createMedicalData(data: $data, files: $files) {
    umdId
  }
}
`
