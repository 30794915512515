import React from 'react'
import { Skeleton } from '@mui/material'

export default function UserProfileSkeleton() {
  return (
    <div
      className="rounded-[10px] bg-white-main p-[25px] laptop:p-[60px] text-[16px]"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <div className="flex justify-center mb-[45px]">
        <Skeleton variant="circular" height="150px" width="150px" />
      </div>

      {Array(4)
        .fill(0)
        .map((_, index: number) => (
          <div
            key={`user-input-skeleton-${index}`}
            className="flex gap-[10px] mb-[10px]"
          >
            <Skeleton variant="rectangular" height="40px" width="20%" />
            <Skeleton variant="rectangular" height="40px" width="80%" />
          </div>
        ))}

      <hr className="border-t-[1px] border-dashed border-gray-dark mt-[30px] mb-[42px]" />

      <Skeleton variant="rectangular" height="40px" width="50%" />
      {Array(2)
        .fill(0)
        .map((_, index: number) => (
          <div
            key={`address-input-skeleton-${index}`}
            className="flex gap-[10px] mt-[10px] mb-[10px]"
          >
            <Skeleton variant="rectangular" height="40px" width="20%" />
            <Skeleton variant="rectangular" height="40px" width="80%" />
          </div>
        ))}
    </div>
  )
}
