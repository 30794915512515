import React from 'react'

import { ElementComponent } from 'components'
import { RegisterType } from 'types'

export default function EnterpriseForm({
  form,
  isLoading,
}: RegisterType.FormProps) {
  return (
    <div>
      <p className="text-[18px] font-bold mb-[30px]">ข้อมูลที่สถานพยาบาล</p>
      <div className="flex flex-col gap-main">
        <ElementComponent.InputBar
          id="enterprise-hn"
          label="เลข HN ของสถานพยาบาล"
          placeholder="เลข HN ของสถานพยาบาล"
          name="enterpriseHN"
          width="100%"
          height="50px"
          value={form.values.enterpriseHN}
          errorText={form.errors.enterpriseHN}
          error={form.touched.enterpriseHN && Boolean(form.errors.enterpriseHN)}
          onChange={(text: string) => form.setFieldValue('enterpriseHN', text)}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}
