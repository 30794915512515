import { createTheme } from '@mui/material'
import breakpoint from './Breakpoint'

const FONT_PRIMARY = 'Sarabun'
const theme = createTheme({ breakpoints: breakpoint })

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightBold: 600,
}

const MuiTypography = {
  subtitle1: {
    fontSize: '24px',
    lineHeight: '35px',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '26px',
      lineHeight: '40px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '28px',
      lineHeight: '45px',
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: '32px',
      lineHeight: '45px',
    },
  },
  subtitle2: {
    fontSize: '20px',
    lineHeight: '30px',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '22px',
      lineHeight: '30px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '24px',
      lineHeight: '35px',
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: '25px',
      lineHeight: '37px',
    },
  },
  body1: {
    fontSize: '14px',
    lineHeight: '20px',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '15px',
      lineHeight: '25px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '18px',
      lineHeight: '30px',
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: '20px',
      lineHeight: '35px',
    },
  },
}

export { typography, MuiTypography }
