import { atom } from 'recoil'

const tokenAtom = atom({
  key: 'token',
  default: {
    token: '',
  },
})

export { tokenAtom }
