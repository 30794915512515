const TOKEN_KEY = 'TOKEN'
const TABLET_BREAKPOINT_WIDTH = 1300

// -> Disposable keys
const CONSULTING_KEY = 'CONSULTING'
const USER_KEY = 'USER'
const REGISTER_KEY = 'REGISTER'
const AUTH_KEY = 'AUTH'
const FORGET_KEY = 'FORGET'

export {
  TABLET_BREAKPOINT_WIDTH,
  TOKEN_KEY,
  CONSULTING_KEY,
  USER_KEY,
  REGISTER_KEY,
  AUTH_KEY,
  FORGET_KEY,
}
