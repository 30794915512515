import { useState, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'

export default function useResponsive() {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const isMobile = useMediaQuery('(max-width:1024px)')
  const isLaptop = useMediaQuery('(min-width:1024px)')

  const changeScreenSize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', changeScreenSize)

    return () => {
      window.removeEventListener('resize', changeScreenSize)
    }
  }, [])

  return { width, height, isMobile, isLaptop }
}
