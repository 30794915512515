export const gets = (condition: object, take: number, skip: number) => {
  const schema = `
    query getEnterpriseTreatmentPlaceTransactions($where: EnterpriseTreatmentPlaceTransactionWhereInput, $take: Int, $skip: Int) {
      getEnterpriseTreatmentPlaceTransactions(where: $where, take: $take, skip: $skip) {
        etptId
        citizenId
        enterprise_hnId
        user {
          hnId
          prefix
          firstname
          lastname
          UserAuth {
            phoneNumber
          }
        }
        createdAt
        status
      }
    }
  `

  return {
    query: schema,
    variables: {
      where: {
        ...condition,
        user: {
          is: {
            role: {
              is: {
                name: {
                  equals: 'PATIENT',
                },
              },
            },
          },
        },
      },
      take: take,
      skip: skip,
    },
  }
}

export const count = (condition: object) => {
  const schema = `
    query countEnterpriseTreatmentPlaceTransaction($where: EnterpriseTreatmentPlaceTransactionWhereInput) {
      countEnterpriseTreatmentPlaceTransaction(where: $where)
    }
  `

  return {
    query: schema,
    variables: {
      where: {
        ...condition,
        user: {
          is: {
            role: {
              is: {
                name: {
                  equals: 'PATIENT',
                },
              },
            },
          },
        },
      },
    },
  }
}
