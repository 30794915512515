import React from 'react'

import { general } from 'utils'
import { Drawer } from '@mui/material'

import { FormComponent } from 'components'

interface LoginMobileProps {
  open: boolean
  onClose: Function
}

export default function LoginMobile({ open, onClose }: LoginMobileProps) {
  return (
    <>
      {!general.auth.isAuthorized() && (
        <Drawer
          id="login-drawer"
          anchor="bottom"
          open={open}
          onClose={() => onClose()}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '10px 10px 0 0',
            },
          }}
        >
          <div className="flex justify-center mt-[32px] mb-[50px] text-center px-[16px]">
            <FormComponent.LoginForm />
          </div>
        </Drawer>
      )}
    </>
  )
}
