import React, { useState } from 'react'
import { Modal } from '@mui/material'
import { Icon } from '@iconify/react'

interface ImageComponentProps {
  src: string
  className?: string
  alt?: string
  fullImage?: boolean
  onError?: Function
}

function ImageComponent({
  src,
  className = '',
  alt = '',
  fullImage = true,
  onError,
}: ImageComponentProps) {
  const [openImage, setOpenImage] = useState<boolean>(false)

  return (
    <div className="w-full h-full">
      <img
        className={`${className} cursor-pointer`}
        src={src}
        alt={alt}
        onClick={() => fullImage && setOpenImage(true)}
        onError={() => onError && onError()}
      />

      <Modal open={openImage} onClose={() => setOpenImage(false)}>
        <div
          className="flex items-center justify-center w-full h-full"
          onClick={() => setOpenImage(false)}
        >
          <div className="relative m-[10px]">
            <img
              className="w-auto h-auto max-w-screen max-h-screen"
              src={src}
              alt=""
            />
            <Icon
              icon="radix-icons:cross-2"
              className="absolute top-[10px] right-[10px] text-white-main rounded-full w-[30px] h-[30px] bg-black-main/50 p-[5px] cursor-pointer"
              onClick={() => setOpenImage(false)}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ImageComponent
