import React from 'react'
import { Grid, Skeleton } from '@mui/material'

interface TableSkeletonProps {
  columnsAmount: number
  rowsAmount?: number
  heigthSkeleton?: number
}

export default function TableSkeleton({
  columnsAmount,
  rowsAmount = 10,
  heigthSkeleton = 50,
}: TableSkeletonProps) {
  return (
    <div className="w-full">
      <div className="bg-white-main w-full px-[20px] rounded-[8px] mt-[15px]">
        {Array(rowsAmount)
          .fill(0)
          .map((_, index: number) => (
            <Grid
              sx={{ marginTop: index === 0 ? '0px' : '10px' }}
              key={`health-column-${index}`}
              container
              columns={columnsAmount}
              columnSpacing="5px"
            >
              {Array(columnsAmount)
                .fill(0)
                .map((_, index: number) => (
                  <Grid item mobile={1} key={`health-rows-${index}`}>
                    <Skeleton
                      variant="rectangular"
                      height={`${heigthSkeleton}px`}
                    />
                  </Grid>
                ))}
            </Grid>
          ))}
      </div>
    </div>
  )
}
