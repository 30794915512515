import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'

import { ElementComponent } from 'components'
import { RegisterType } from 'types'
import { endpoints } from 'settings'

export default function AddressForm({
  form,
  isLoading,
}: RegisterType.FormProps) {
  const [provinceId, setProvinceId] = useState<number>(0)
  const [province, setProvince] = useState<RegisterType.ProvinceListType[]>([
    { id: 0, name: '' },
  ])
  const [district, setDistrict] = useState<RegisterType.DistrictType[]>([
    { name: '' },
  ])

  const provinceOptions = useMemo(() => {
    if (province.length > 1)
      return [
        { key: 'จังหวัด', value: 'จังหวัด' },
        ...province.map((value) => ({ key: value.name, value: value.name })),
      ]
    return [{ key: 'จังหวัด', value: 'จังหวัด' }]
  }, [province])

  const districtOptions = useMemo(() => {
    if (district.length > 1)
      return [
        { key: 'อำเภอ/เขต', value: 'อำเภอ/เขต' },
        ...district.map((value) => ({ key: value.name, value: value.name })),
      ]
    return [{ key: 'อำเภอ/เขต', value: 'อำเภอ/เขต' }]
  }, [district])

  useEffect(() => {
    ;(async () => {
      const response = await axios.get(endpoints.PROVINCE_DATA_URL)
      const resData: { province_id: number; name_th: string }[] =
        await response.data
      setDistrict(
        resData
          .filter((item) => item.province_id === provinceId)
          .map((item) => ({ name: item.name_th })),
      )
    })()
  }, [provinceId])

  useEffect(() => {
    ;(async () => {
      const response = await axios.get(endpoints.DISTRICT_DATA_URL)
      const resData = await response.data
      setProvince(resData.map((item) => ({ id: item.id, name: item.name_th })))

      if (form.values.province) {
        setProvinceId(
          resData.filter((item) => item.name_th === form.values.province)[0]
            ?.id,
        )
      }
    })()
  }, [])

  const handleProvinceChange = async (text: any) => {
    if (text) {
      await form.setFieldValue('province', text.value)
      await form.setFieldValue('district', '')
      setProvinceId(
        province.filter((item) => item.name === text.value)[0]?.id ?? 0,
      )
    }
  }

  const handleDistrictChange = (text: any) => {
    form.setFieldValue('district', text.value)
  }

  return (
    <div>
      <p className="text-[18px] font-bold mb-[30px]">ข้อมูลที่อยู่</p>
      <div className="flex flex-col gap-main">
        <ElementComponent.SearchBar
          name="province"
          label="จังหวัด"
          isLoading={isLoading}
          isSubmitting={form.isSubmitting}
          data={form.values.province}
          error={form.touched.province && Boolean(form.errors.province)}
          errorText={form.errors.province}
          option={provinceOptions}
          onSelect={handleProvinceChange}
        />

        <ElementComponent.SearchBar
          name="district"
          label="อำเภอ/เขต"
          isLoading={isLoading}
          isSubmitting={form.isSubmitting}
          data={form.values.district}
          error={form.touched.district && Boolean(form.errors.district)}
          errorText={form.errors.district}
          option={districtOptions}
          disabled={Boolean(form.values.province === '')}
          onSelect={handleDistrictChange}
        />
      </div>
    </div>
  )
}
